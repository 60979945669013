import CardOrderActionTypes from './card-order.types'

const INITIAL_STATE = {
  cardOrders: {
    data: []
  },
  cardOrder: null,
  loadingCardOrders: false,
  loadingCardOrder: false,
  cardOrderStatus: 0,
  loadingCardOrderReview: false,
  cardOrderErrorMsg: ''
};

const CardOrderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CardOrderActionTypes.GET_CARD_ORDERS:
      return {
        ...state,
        loadingCardOrders: true,
        cardOrders: {
          data: []
        }
      }
    case CardOrderActionTypes.GET_CARD_ORDERS_SUCCESS: 
      return {
        ...state,
        loadingCardOrders: false,
        cardOrders: action.payload
      }
    case CardOrderActionTypes.GET_CARD_ORDERS_FAILURE:
      return {
        ...state,
        loadingCardOrders: false,
        cardOrders: {
          data: []
        }
      }

    case CardOrderActionTypes.GET_CARD_ORDER:
      return {
        ...state,
        cardOrder: null,
        loadingCardOrder: true
      }

    case CardOrderActionTypes.GET_CARD_ORDER_SUCCESS:
      return {
        ...state,
        loadingCardOrder: false,
        cardOrder: action.payload.data
      }

    case CardOrderActionTypes.GET_CARD_ORDER_FAILURE:
      return {
        ...state,
        cardOrder: null,
        loadingCardOrder: false
      }
    
    case CardOrderActionTypes.UPDATE_CARD_ORDERS_REVIEW: 
      return {
          ...state,
          loadingCardOrderReview: true
      }
      
    case CardOrderActionTypes.UPDATE_CARD_ORDERS_REVIEW_SUCCESS: 
      return {
          ...state,
          loadingCardOrderReview: false
      }
      
    case CardOrderActionTypes.UPDATE_CARD_ORDERS_REVIEW_FAILURE: 
      return {
          ...state,
          loadingCardOrderReview: false,
          cardOrderErrorMsg: action.payload
      }
      
    case CardOrderActionTypes.CONFIRM_DELIVERY_CARD_ORDERS: 
      return {
          ...state,
          loadingConfirmDeliverCardOrders: true
      }
      
    case CardOrderActionTypes.CONFIRM_DELIVERY_CARD_ORDERS_SUCCESS: 
      return {
          ...state,
          loadingConfirmDeliverCardOrders: false,
          cardOrderStatus: action.payload.data.status_order
      }
      
    case CardOrderActionTypes.CONFIRM_DELIVERY_CARD_ORDERS_FAILURE: 
      return {
          ...state,
          loadingConfirmDeliverCardOrders: false,
          cardOrderErrorMsg: action.payload
      } 

    default:
      return state
  }
};

export default CardOrderReducer;
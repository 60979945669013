const EditProfileActionTypes = {
    GET_PROFILE: "GET_PROFILE",
    GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
    GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",
    UPDATE_PROFILE: "UPDATE_PROFILE",
    UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
    UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
    UPDATE_PASSWORD: "UPDATE_PASSWORD",
    UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
    SET_MESSAGE_UPDATE_OFF: "SET_MESSAGE_UPDATE_OFF",
  };
  
  export default EditProfileActionTypes;
  
const CardOrderActionTypes = {
    GET_CARD_ORDERS: "GET_CARD_ORDERS",
    GET_CARD_ORDERS_SUCCESS: "GET_CARD_ORDERS_SUCCESS",
    GET_CARD_ORDERS_FAILURE: "GET_CARD_ORDERS_FAILURE",
    GET_CARD_ORDER: "GET_CARD_ORDER",
    GET_CARD_ORDER_SUCCESS: "GET_CARD_ORDER_SUCCESS",
    GET_CARD_ORDER_FAILURE: "GET_CARD_ORDER_FAILURE",
    UPDATE_CARD_ORDERS_REVIEW: "UPDATE_CARD_ORDERS_REVIEW",
    UPDATE_CARD_ORDERS_REVIEW_SUCCESS: "UPDATE_CARD_ORDERS_REVIEW_SUCCESS",
    UPDATE_CARD_ORDERS_REVIEW_FAILURE: "UPDATE_CARD_ORDERS_REVIEW_FAILURE",
    CONFIRM_DELIVERY_CARD_ORDERS: "CONFIRM_DELIVERY_CARD_ORDERS",
    CONFIRM_DELIVERY_CARD_ORDERS_SUCCESS: "CONFIRM_DELIVERY_CARD_ORDERS_SUCCESS",
    CONFIRM_DELIVERY_CARD_ORDERS_FAILURE: "CONFIRM_DELIVERY_CARD_ORDERS_FAILURE"
};

export default CardOrderActionTypes;
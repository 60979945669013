import AccountActionTypes from './account.types'

const INITIAL_STATE = {
    account: null,
    error: false,
    loading: false,
    success: false
  };
  
  const AccountReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
          case AccountActionTypes.GET_ACCOUNT:
          case AccountActionTypes.RESET_PSSWORD:
            return {
              ...state,
              loading: true,
              error: false,
            }
          case AccountActionTypes.GET_ACCOUNT_SUCCESS:
            return {
              ...state,
              loading: false,
              error: false,
              account: action.payload,
            }
       case AccountActionTypes.RESET_PASSWORD_SUCCESS:
            return {
              ...state,
              loading: false,
              error: false,
              success: true
            }
        case AccountActionTypes.GET_ACCOUNT_FAILURE:
        case AccountActionTypes.RESET_PASSWORD_FAILURE:
            return {
              ...state,
              loading: false,
              error: true,
            }
        case AccountActionTypes.DEFAULT_ACCOUNT:
          return {
            ...state,
            account: null,
          }
        default: return state
    }
  };
  
  export default AccountReducer;
  
const BillingActionTypes = {
    UPDATE_BILLING_INFORMATION: "UPDATE_BILLING_INFORMATION",
    UPDATE_BILLING_INFORMATION_SUCCESS: "UPDATE_BILLING_INFORMATION_SUCCESS",
    UPDATE_BILLING_INFORMATION_FAILURE: "UPDATE_BILLING_INFORMATION_FAILURE",
    GET_BILLING_INFORMATION: "GET_BILLING_INFORMATION",
    GET_BILLING_INFORMATION_SUCCESS: "GET_BILLING_INFORMATION_SUCCESS",
    GET_BILLING_INFORMATION_FAILURE: "GET_BILLING_INFORMATION_FAILURE",
  };
  
  export default BillingActionTypes;
  
const CardOrderBatchActionTypes = {
  GET_CARD_ORDER_BATCHES: "GET_CARD_ORDER_BATCHES",
  GET_CARD_ORDER_BATCHES_SUCCESS: "GET_CARD_ORDER_BATCHES_SUCCESS",
  GET_CARD_ORDER_BATCHES_FAILURE: "GET_CARD_ORDER_BATCHES_FAILURE",
  GET_CARD_ORDER_TO_BATCH: "GET_CARD_ORDER_TO_BATCH",
  GET_CARD_ORDER_TO_BATCH_SUCCESS: "GET_CARD_ORDER_TO_BATCH_SUCCESS",
  GET_CARD_ORDER_TO_BATCH_FAILURE: "GET_CARD_ORDER_TO_BATCH_FAILURE",
  GET_DETAIL_CARD_ORDER_BATCH: "GET_DETAIL_CARD_ORDER_BATCH",
  GET_DETAIL_CARD_ORDER_BATCH_SUCCESS: "GET_DETAIL_CARD_ORDER_BATCH_SUCCESS",
  GET_DETAIL_CARD_ORDER_BATCH_FAILURE: "GET_DETAIL_CARD_ORDER_BATCH_FAILURE",
  CREATE_CARD_ORDER_BATCH: "CREATE_CARD_ORDER_BATCH",
  CREATE_CARD_ORDER_BATCH_SUCCESS: "CREATE_CARD_ORDER_BATCH_SUCCESS",
  CREATE_CARD_ORDER_BATCH_FAILURE: "CREATE_CARD_ORDER_BATCH_FAILURE",
  GENERATE_CARD_NUMBERS: "GENERATE_CARD_NUMBERS",
  GENERATE_CARD_NUMBERS_SUCCESS: "GENERATE_CARD_NUMBERS_SUCCESS",
  GENERATE_CARD_NUMBERS_FAILURE: "GENERATE_CARD_NUMBERS_FAILURE",
  CLICK_BATCH_CREATED: "CLICK_BATCH_CREATED",
  CLICK_CARD_ASSIGNED: "CLICK_CARD_ASSIGNED",
  CARD_ORDER_BATCH_STEP: "CARD_ORDER_BATCH_STEP"
};

export default CardOrderBatchActionTypes;
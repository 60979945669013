const CardTemplateActionTypes = {
    CREATE_CARD_TEMPLATE: "CREATE_CARD_TEMPLATE",
    CREATE_CARD_TEMPLATE_SUCCESS: "CREATE_CARD_TEMPLATE_SUCCESS",
    CREATE_CARD_TEMPLATE_FAILURE: "CREATE_CARD_TEMPLATE_FAILURE",
    UPDATE_CARD_TEMPLATE: "UPDATE_CARD_TEMPLATE",
    UPDATE_CARD_TEMPLATE_SUCCESS: "UPDATE_CARD_TEMPLATE_SUCCESS",
    UPDATE_CARD_TEMPLATE_FAILURE: "UPDATE_CARD_TEMPLATE_FAILURE",
    GET_CARD_TEMPLATE: "GET_CARD_TEMPLATE",
    GET_CARD_TEMPLATE_SUCCESS: "GET_CARD_TEMPLATE_SUCCESS",
    GET_CARD_TEMPLATE_FAILURE: "GET_CARD_TEMPLATE_FAILURE",
    GET_CARD_TEMPLATES: "GET_CARD_TEMPLATES",
    GET_CARD_TEMPLATES_SUCCESS: "GET_CARD_TEMPLATES_SUCCESS",
    GET_CARD_TEMPLATES_FAILURE: "GET_CARD_TEMPLATES_FAILURE",
    GET_EDIT_CARD_TEMPLATE: "GET_EDIT_CARD_TEMPLATE",
    GET_EDIT_CARD_TEMPLATE_SUCCESS: "GET_EDIT_CARD_TEMPLATE_SUCCESS",
    GET_EDIT_CARD_TEMPLATE_FAILURE: "GET_EDIT_CARD_TEMPLATE_FAILURE",
    DELETE_CARD_TEMPLATE: "DELETE_CARD_TEMPLATE",
    DELETE_CARD_TEMPLATE_SUCCESS: "DELETE_CARD_TEMPLATE_SUCCESS",
    DELETE_CARD_TEMPLATE_FAILURE: "DELETE_CARD_TEMPLATE_FAILURE",
    GET_LIST_CARD_TEMPLATE: "GET_LIST_CARD_TEMPLATE",
    GET_LIST_CARD_TEMPLATE_SUCCESS: "GET_LIST_CARD_TEMPLATE_SUCCESS",
    GET_LIST_CARD_TEMPLATE_FAILURE: "GET_LIST_CARD_TEMPLATE_FAILURE",
    GET_CARD_NETWORK: "GET_CARD_NETWORK",
    GET_CARD_NETWORK_SUCCESS: "GET_CARD_NETWORK_SUCCESS",
    GET_CARD_NETWORK_FAILURE: "GET_CARD_NETWORK_FAILURE",
    GET_CARD_NETWORK_TYPE: "GET_CARD_NETWORK_TYPE",
    GET_CARD_NETWORK_TYPE_SUCCESS: "GET_CARD_NETWORK_TYPE_SUCCESS",
    GET_CARD_NETWORK_TYPE_FAILURE: "GET_CARD_NETWORK_TYPE_FAILURE",
    GET_CARD_TEMPLATE_MATRIX: "GET_CARD_TEMPLATE_MATRIX",
    GET_CARD_TEMPLATE_MATRIX_SUCCESS: "GET_CARD_TEMPLATE_MATRIX_SUCCESS",
    GET_CARD_TEMPLATE_MATRIX_FAILURE: "GET_CARD_TEMPLATE_MATRIX_FAILURE",
    GET_STATE_CODE: "GET_STATE_CODE",
    GET_STATE_CODE_SUCCESS: "GET_STATE_CODE_SUCCESS",
    GET_STATE_CODE_FAILURE: "GET_STATE_CODE_FAILURE",
    GET_CARD_PERIOD_GALLON: "GET_CARD_PERIOD_GALLON",
    GET_CARD_PERIOD_GALLON_SUCCESS: "GET_CARD_PERIOD_GALLON_SUCCESS",
    GET_CARD_PERIOD_GALLON_FAILURE: "GET_CARD_PERIOD_GALLON_FAILURE",
    GET_CARD_PERIOD_AMOUNT: "GET_CARD_PERIOD_AMOUNT",
    GET_CARD_PERIOD_AMOUNT_SUCCESS: "GET_CARD_PERIOD_AMOUNT_SUCCESS",
    GET_CARD_PERIOD_AMOUNT_FAILURE: "GET_CARD_PERIOD_AMOUNT_FAILURE",
    GET_VEHICLE_TYPE: "GET_VEHICLE_TYPE",
    GET_VEHICLE_TYPE_SUCCESS: "GET_VEHICLE_TYPE_SUCCESS",
    GET_VEHICLE_TYPE_FAILURE: "GET_VEHICLE_TYPE_FAILURE",
    GET_TIME_PROFILE: "GET_TIME_PROFILE",
    GET_TIME_PROFILE_SUCCESS: "GET_TIME_PROFILE_SUCCESS",
    GET_TIME_PROFILE_FAILURE: "GET_TIME_PROFILE_FAILURE",
    GET_FUEL_PROFILE: "GET_FUEL_PROFILE",
    GET_FUEL_PROFILE_SUCCESS: "GET_FUEL_PROFILE_SUCCESS",
    GET_FUEL_PROFILE_FAILURE: "GET_FUEL_PROFILE_FAILURE",
    BULK_DELETE_CARD_TEMPLATE: "BULK_DELETE_CARD_TEMPLATE",
    BULK_DELETE_CARD_TEMPLATE_SUCCESS: "BULK_DELETE_CARD_TEMPLATE_SUCCESS",
    BULK_DELETE_CARD_TEMPLATE_FAILURE: "BULK_DELETE_CARD_TEMPLATE_FAILURE"
  };
  
  export default CardTemplateActionTypes;
  
import React, { useState, useEffect, useCallback } from 'react';
import { IconContext } from "react-icons";
import { BsGridFill, BsExclamationCircleFill, BsFillPersonFill, BsFillPersonCheckFill } from 'react-icons/bs'
import { FaAddressCard } from 'react-icons/fa'

// import Components 
import { Row, Col, Nav, NavDropdown } from "react-bootstrap";
import {useSelector} from 'react-redux';


// import all styles
import "./menus.styles.scss";


const Menus = ({ history }) => {
    const {location: {pathname}} = history;

    const { user } = useSelector((state) => state.auth)

    const menuTitleCard = <div className="menu"><IconContext.Provider value={{ size: '15px' }}><span className="header-icon"><FaAddressCard /></span></IconContext.Provider>Card Maintenance</div>;
    const menuTitleReport = <div className="menu"><IconContext.Provider value={{ size: '15px' }}><span className="header-icon"><BsExclamationCircleFill /></span></IconContext.Provider>Report</div>;
    const menuTitleAccount = <div className="menu"><IconContext.Provider value={{ size: '15px' }}><span className="header-icon"><BsFillPersonCheckFill /></span></IconContext.Provider>Account Maintenance</div>;


    const listRoleAccess = {
        dashboard: ['acct admin', 'acct manager', 'card editor', 'card manager', 'cu sales manager', 'finance manager', 'pin manager 1', 'pin manager 2', 'report user', 'invoice inquiry user', 'billing information user'],
        card: ['acct admin', 'acct manager', 'card editor', 'card manager', 'cu sales manager'],
        reports: ['cu sales manager', 'report user'],
        billingInfo: ['acct admin', 'acct manager', 'card editor', 'card manager', 'cu sales manager', 'finance manager', 'pin manager 1', 'pin manager 2', 'billing information user'],
        deliveryAddress: ['acct admin', 'acct manager', 'card editor', 'card manager', 'cu sales manager', 'finance manager', 'pin manager 1', 'pin manager 2', 'billing information user'],
        user: ['acct admin', 'acct manager', 'card editor', 'card manager', 'cu sales manager', 'finance manager', 'pin manager 1', 'pin manager 2', 'report user', 'invoice inquiry user', 'billing information user']
    }
    // console.log('user :>> ', user);

    const menuPermission = (menu) => {
        let status = false
        let roles = user.roles
        roles.map((role) => {
            if (listRoleAccess[menu].includes(role.toLowerCase())) {
                status = true
            }
        })
        // console.log('status :>> ', status);
        return status
    }

    // console.log('user.user_type_id :>> ', user.user_type_id);
    // console.log('user.roles :>> ', user.roles);

    const render = () => {
        return <Row className="menu-wrapper">
            <Col lg={12} md={12}>
                {
                    user.user_type_id === 1 ?
                    <div className="menu-container">
                        <Nav activeKey="/home">
                            <Nav.Item className="header-icon-container">
                                <Nav.Link className="menu" active={pathname === '/dashboard' ? true : false} eventKey="link-1" onClick={() => history.push('/dashboard')} >
                                    <IconContext.Provider
                                        value={{ size: '15px' }}
                                    >
                                        <span className="header-icon">
                                            <BsGridFill />
                                        </span>
                                    </IconContext.Provider>
                                    <span>
                                        Dashboard
                                    </span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="header-icon-container">
                                <NavDropdown active={['/card-management', '/card-template', '/card-order', '/card-review', '/card-order-batch'].includes(pathname)} title={menuTitleCard} id="nav-dropdown">
                                    <NavDropdown.Item active={pathname === '/card-management' ? true : false} eventKey="4.1" onClick={() => history.push('/card-management')}>Edit Cards</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/card-template' ? true : false} eventKey="4.1" onClick={() => history.push('/card-template')}>Card Templates</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/card-review' ? true : false} eventKey="4.1" onClick={() => history.push('/card-review')}>Card Review</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/card-order-batch' ? true : false} eventKey="4.1" onClick={() => history.push('/card-order-batch')}>Batches</NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                            <Nav.Item className="header-icon-container">
                                <NavDropdown active={['/transaction-detail-report', '/fuel-summary-report', '/billing-sort-report', '/list-driver-or-vehicle-report', '/exception-report', '/tax-summary-report', '/owner-operator-report', '/daily-transaction-report', '/scheduled-exception-report', '/invoiced-transaction-report', '/driver-vehicle-records-monthly-audit-report', '/tax-exempt-report', 'submit-log-report', 'user-log-report'].includes(pathname)} title={menuTitleReport} id="nav-dropdown">
                                    <NavDropdown.Header>On Demand Report</NavDropdown.Header>
                                    <NavDropdown.Item active={pathname === '/transaction-detail-report' ? true : false} eventKey="4.1" onClick={() => history.push('/transaction-detail-report')}>Transaction Detail Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/fuel-summary-report' ? true : false} eventKey="4.1" onClick={() => history.push('/fuel-summary-report')}>Fuel Summary Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/billing-sort-report' ? true : false} eventKey="4.1" onClick={() => history.push('/billing-sort-report')}>Billing Sort Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/list-driver-or-vehicle-report' ? true : false} eventKey="4.1" onClick={() => history.push('/list-driver-or-vehicle-report')}>List Driver or Vehicle Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/exception-report' ? true : false} eventKey="4.1" onClick={() => history.push('/exception-report')}>Exception Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/tax-summary-report' ? true : false} eventKey="4.1" onClick={() => history.push('/tax-summary-report')}>Tax Summary Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/owner-operator-report' ? true : false} eventKey="4.1" onClick={() => history.push('/owner-operator-report')}>Owner Operator Report</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Header>Admin Report</NavDropdown.Header>
                                    <NavDropdown.Item active={pathname === '/tax-exempt-report' ? true : false} eventKey="4.1" onClick={() => history.push('/tax-exempt-report')}>Tax Exempt Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/submit-log-report' ? true : false} eventKey="4.1" onClick={() => history.push('/submit-log-report')}>Submit Log Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/user-log-report' ? true : false} eventKey="4.1" onClick={() => history.push('/user-log-report')}>User Log Report</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Header>Scheduled Report</NavDropdown.Header>
                                    <NavDropdown.Item active={pathname === '/daily-transaction-report' ? true : false} eventKey="4.1" onClick={() => history.push('/daily-transaction-report')}>Daily Transaction Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/scheduled-exception-report' ? true : false} eventKey="4.1" onClick={() => history.push('/scheduled-exception-report')}>Exception Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/invoiced-transaction-report' ? true : false} eventKey="4.1" onClick={() => history.push('/invoiced-transaction-report')}>Invoiced Transaction Report</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/driver-vehicle-records-monthly-audit-report' ? true : false} eventKey="4.1" onClick={() => history.push('/driver-vehicle-records-monthly-audit-report')}>Driver and Vehicle Records Monthly Audit Report</NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                            <Nav.Item className="header-icon-container">
                                <NavDropdown active={['/billing-information', '/delivery-address', '/user'].includes(pathname)} title={menuTitleAccount} id="nav-dropdown">
                                    <NavDropdown.Item active={pathname === '/billing-information' ? true : false} eventKey="4.1" onClick={() => history.push('/billing-information')}> Billing Information</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/delivery-address' ? true : false} eventKey="4.1" onClick={() => history.push('/delivery-address')}>Delivery Addresses</NavDropdown.Item>
                                    <NavDropdown.Item active={pathname === '/user' ? true : false} eventKey="4.1" onClick={() => history.push('/user')}>User Maintenance</NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        </Nav>
                    </div>
                    :
                    <div className="menu-container">
                        <Nav activeKey="/home">
                            {
                                menuPermission('dashboard') &&
                                <Nav.Item className="header-icon-container">
                                    <Nav.Link className="menu" active={pathname === '/dashboard' ? true : false} eventKey="link-1" onClick={() => history.push('/dashboard')} >
                                        <IconContext.Provider
                                            value={{ size: '15px' }}
                                        >
                                            <span className="header-icon">
                                                <BsGridFill />
                                            </span>
                                        </IconContext.Provider>
                                        <span>
                                            Dashboard
                                        </span>
                                    </Nav.Link>
                                </Nav.Item>
                            }
                            {
                                menuPermission('card') &&
                                <Nav.Item className="header-icon-container">
                                    <NavDropdown active={['/card-management', '/card-template', '/card-order', '/card-review', '/card-order-batch'].includes(pathname)} title={menuTitleCard} id="nav-dropdown">
                                        <NavDropdown.Item active={pathname === '/card-management' ? true : false} eventKey="4.1" onClick={() => history.push('/card-management')}>Edit Cards</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/card-order' ? true : false} eventKey="4.1" onClick={() => history.push('/card-order')}>Card Orders</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Item>
                            }
                            {
                                menuPermission('reports') &&
                                <Nav.Item className="header-icon-container">
                                    <NavDropdown active={['/transaction-detail-report', '/fuel-summary-report', '/billing-sort-report', '/list-driver-or-vehicle-report', '/exception-report', '/tax-summary-report', '/owner-operator-report', '/daily-transaction-report', '/scheduled-exception-report', '/invoiced-transaction-report', '/driver-vehicle-records-monthly-audit-report'].includes(pathname)} title={menuTitleReport} id="nav-dropdown">
                                        <NavDropdown.Header>On Demand Report</NavDropdown.Header>
                                        <NavDropdown.Item active={pathname === '/transaction-detail-report' ? true : false} eventKey="4.1" onClick={() => history.push('/transaction-detail-report')}>Transaction Detail Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/fuel-summary-report' ? true : false} eventKey="4.1" onClick={() => history.push('/fuel-summary-report')}>Fuel Summary Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/billing-sort-report' ? true : false} eventKey="4.1" onClick={() => history.push('/billing-sort-report')}>Billing Sort Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/list-driver-or-vehicle-report' ? true : false} eventKey="4.1" onClick={() => history.push('/list-driver-or-vehicle-report')}>List Driver or Vehicle Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/exception-report' ? true : false} eventKey="4.1" onClick={() => history.push('/exception-report')}>Exception Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/tax-summary-report' ? true : false} eventKey="4.1" onClick={() => history.push('/tax-summary-report')}>Tax Summary Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/owner-operator-report' ? true : false} eventKey="4.1" onClick={() => history.push('/owner-operator-report')}>Owner Operator Report</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Header>Scheduled Report</NavDropdown.Header>
                                        <NavDropdown.Item active={pathname === '/daily-transaction-report' ? true : false} eventKey="4.1" onClick={() => history.push('/daily-transaction-report')}>Daily Transaction Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/scheduled-exception-report' ? true : false} eventKey="4.1" onClick={() => history.push('/scheduled-exception-report')}>Exception Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/invoiced-transaction-report' ? true : false} eventKey="4.1" onClick={() => history.push('/invoiced-transaction-report')}>Invoiced Transaction Report</NavDropdown.Item>
                                        <NavDropdown.Item active={pathname === '/driver-vehicle-records-monthly-audit-report' ? true : false} eventKey="4.1" onClick={() => history.push('/driver-vehicle-records-monthly-audit-report')}>Driver and Vehicle Records Monthly Audit Report</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Item>
                            }
                            {/* <Nav.Item className="header-icon-container">
                                <Nav.Link className="menu" active={pathname === '/user' ? true : false} eventKey="link-1" onClick={() => history.push('/user')}>
                                    <IconContext.Provider
                                        value={{ size: '15px' }}
                                    >
                                        <span className="header-icon">
                                            <BsFillPersonFill />
                                        </span>
                                    </IconContext.Provider>
                                    <span>User Maintenance</span>
                                </Nav.Link>
                            </Nav.Item> */}
                            {/* <Nav.Item className="header-icon-container">
                                <IconContext.Provider
                                    value={{ size: '15px' }}
                                >
                                    <span className="header-icon">
                                        <BsFillPersonCheckFill />
                                    </span>
                                </IconContext.Provider>
                                <Nav.Link eventKey="link-1" onClick={() => history.push('/account')} >Account Maintenance</Nav.Link>
                            </Nav.Item> */}
                            {
                                menuPermission('user') &&
                                <Nav.Item className="header-icon-container">
                                    <NavDropdown active={['/billing-information', '/delivery-address', '/user'].includes(pathname)} title={menuTitleAccount} id="nav-dropdown">
                                        {
                                            menuPermission('billingInfo') &&
                                            <NavDropdown.Item active={pathname === '/billing-information' ? true : false} eventKey="4.1" onClick={() => history.push('/billing-information')}> Billing Information</NavDropdown.Item>
                                        }
                                        {
                                            menuPermission('deliveryAddress') &&
                                            <NavDropdown.Item active={pathname === '/delivery-address' ? true : false} eventKey="4.1" onClick={() => history.push('/delivery-address')}>Delivery Addresses</NavDropdown.Item>
                                        }
                                        <NavDropdown.Item active={pathname === '/user' ? true : false} eventKey="4.1" onClick={() => history.push('/user')}>User Maintenance</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Item>
                            }
                        </Nav>
                    </div>
                }
            </Col>
        </Row>
    }

    return render();
};
export default Menus;

import React, { Suspense, useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PopupAction from './../components/popup-action/popup-action.component';
import Loading from './../components/loading/loading.component'
// import routes from './role';
import {
 SigninPage,
 AccountPage,
 ForgetPasswordPage,
 DashboardPage,
 CardManagementPage,
 CardTemplatePage,
 UserPage,
 ResetPasswordPage,
 AccountManagementPage,
 CardOrderPage,
 CardReviewPage,
 CardOrderReviewPage,
 ResetPasswordMessagePage,
 PrivacyPolicyPage,
 WebsiteAgreementPage,
 TermAndConditionPage,
 AccountActivationPage,
 EditProfilePage,
 DeliveryAddressPage,
 BillingInformationPage,
 CardOrderBatchPage,
 CreateOrderBatchPage,
 TransactionDetailReport,
 FuelSummaryReport,
 BillingSortReport,
 ListDriverOrVehicleReport,
 ExceptionReport,
 TaxSummaryReport,
 OwnerOperatorReport,
 DailyTransactionReport,
 ScheduledExceptionReport,
 InvoicedTransactionReport,
 DriverVehicleRecordsMonthlyAuditReport,
 TaxExemptReport,
 SubmitLogReport,
 UserLogReport
} from '../containers'
import FormCardAdd from '../containers/card-management/form-card-add.component';
import { getAccount } from '../redux/account/account.actions';
import { verifyAccount } from '../redux/authentication/auth.actions';

const routes = [
  {
    path: '/dashboard',
    component: DashboardPage,
    exact: true
  },
  {
    path: '/card-management',
    component: CardManagementPage,
    exact: true
  },
  {
    path: '/card-template-form',
    component: FormCardAdd,
    exact: true
  },
  {
    path: '/user',
    component: UserPage,
    exact: true
  },
  {
    path: '/account',
    component: AccountManagementPage,
    exact: true
  },
  {
    path: '/edit-password',
    component: EditProfilePage,
    exact: true
  },
  {
    path: '/delivery-address',
    component: DeliveryAddressPage,
    exact: true
  },
  {
    path: '/billing-information',
    component: BillingInformationPage,
    exact: true
  },
  {
    path: '/transaction-detail-report',
    component: TransactionDetailReport,
    exact: true
  },
  {
    path: '/fuel-summary-report',
    component: FuelSummaryReport,
    exact: true
  },
  {
    path: '/billing-sort-report',
    component: BillingSortReport,
    exact: true
  },
  {
    path: '/list-driver-or-vehicle-report',
    component: ListDriverOrVehicleReport,
    exact: true
  },
  {
    path: '/exception-report',
    component: ExceptionReport,
    exact: true
  },
  {
    path: '/tax-summary-report',
    component: TaxSummaryReport,
    exact: true
  },
  {
    path: '/owner-operator-report',
    component: OwnerOperatorReport,
    exact: true
  },
  {
    path: '/daily-transaction-report',
    component: DailyTransactionReport,
    exact: true
  },
  {
    path: '/scheduled-exception-report',
    component: ScheduledExceptionReport,
    exact: true
  },
  {
    path: '/invoiced-transaction-report',
    component: InvoicedTransactionReport,
    exact: true
  },
  {
    path: '/driver-vehicle-records-monthly-audit-report',
    component: DriverVehicleRecordsMonthlyAuditReport,
    exact: true
  },
  {
    path: '/tax-exempt-report',
    component: TaxExemptReport,
    exact: true
  },
  {
    path: '/submit-log-report',
    component: SubmitLogReport,
    exact: true
  },
  {
    path: '/user-log-report',
    component: UserLogReport,
    exact: true
  },
]

const WithAuthenRoute = ({ component: Component, user, account, data, accounts, ...others }) => {
  return (
    <Route
      {...others}
      render={(props) => {
        if (user && data.status === 0 && account) {
          return (
              <Component {...props} />
          )
        }
        if (user && data.status === 0 && !account && ( accounts && accounts?.countAccounts !== 1)){
          return <Redirect to='/role/account' />
        }
        if (user && data.status === 3 && !account) {
          return <Redirect to='/term-and-condition' />
        }
        return <Redirect to='/auth/signin' />
      }}
    />
  )
}

const renderAuthenticatedRoute = (user, account, data, accounts) => {
  return routes.map(({ path, component }) => (
    <WithAuthenRoute key={path} path={path} component={component} user={user} data={data} account={account} accounts={accounts} />
  ))
}

function Routes() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.token)
  const data = useSelector((state) => state.auth.user)
  const account = useSelector((state) => state.auth.account)
  const accountActivated = useSelector((state) => state.auth.accountActivated)
  const {showPopup, popupData, showLoading} = useSelector((state) => state.user)
  const accounts = useSelector((state) => state.account.account);
  const [ isShow, setIsShow ] = useState(false);

  useEffect(() => {
    setIsShow(showPopup);
  }, [showPopup]);

  // useEffect(() => {
  //   dispatch(getAccount());
  // }, [dispatch, user]);

  // useEffect(() => {
  //   if(accounts && accounts.countAccounts === 1) dispatch(verifyAccount(accounts.accounts[0].account_number));
  // }, [accounts]);

  return (
    <Suspense fallback=''>
      {
        showPopup && popupData && <PopupAction data={popupData}/>
      }
      {
        showLoading && <Loading />
      }
      <Switch>
        <Route exact path='/' render={() => <Redirect to='/dashboard' />} />
        {renderAuthenticatedRoute(user, account, data)}
        <Route
          path='/auth'
          render={({ match: { url } }) => (
            <>
              <Route
                path={`${url}/`}
                render={({children}) => {
                  if (user && data.status === 0 && account) {
                    return <Redirect to='/dashboard' />
                  }
                  // if (user && data.status === 0 && !account && ( accounts && accounts?.countAccounts !== 1)) {
                  if (user && data.status === 0 && account === null) {
                    return <Redirect to='/role/account' />
                  }
                  if (user && data.status === 3 && !account){
                    return <Redirect to='/term-and-condition' />
                  }
                  if(accountActivated){
                      return <Redirect to='signin' />
                  }
                  return children
                }}             
              />
              <Route exact path={`${url}/signin`} component={SigninPage} />
              <Route exact path={`${url}/forgot-password`} component={ForgetPasswordPage} />
              <Route exact path={`${url}/reset-password`} component={ResetPasswordPage} />
              <Route exact path={`${url}/account-activation`} component={AccountActivationPage} />
              <Route exact path={`${url}/message-forgot-password`} component={ResetPasswordMessagePage} />
            </>
          )}
        />
         <Route
          path='/role'
          render={({ match: { url } }) => (
            <>
              <Route
                path={`${url}/`}
                render={({children}) => {
                  if (user && data.status === 0 && account) {
                    return <Redirect to='/dashboard' />
                  }
                  if (user && data.status === 3 && !account){
                    return <Redirect to='/term-and-condition' />
                  }
                  if (!user) {
                    return <Redirect to='/auth/signin' />
                  }
                  return children
                }}             
              />
               <Route exact path={`${url}/account`} component={AccountPage} />
            </>
          )}
        />
        <Route
          path='/term-and-condition'
          render={({ match: { url } }) => {
            if (user && data.status === 0 && account) {
              return <Redirect to='/dashboard' />
            }
            if (user && data.status === 0 && !account) {
              return <Redirect to='/role/account' />
            }
            if (!user) {
              return <Redirect to='/auth/signin' />
            }
            return <Route exact path={`${url}`} component={TermAndConditionPage} />
          }}             
        />
         <Route
          path='/privacy-policy'
          exact
          component={PrivacyPolicyPage}
        />
        <Route
          path='/website-agreement'
          exact
          component={WebsiteAgreementPage}
        />
        <Route 
          path='/card-template'
          render={({ match: { url } }) => {
            if (user && data.status === 0 && account) {
              if (data.user_type_id === 1) {
                return <>
                  <Route exact path={`${url}`} component={CardTemplatePage} />
                </>
              } else {
                return <Redirect to='/dashboard' />
              }
            }
            if (user && data.status === 0 && !account) {
              return <Redirect to='/role/account' />
            }
            if (!user) {
              return <Redirect to='/auth/signin' />
            }
          }}
        />
        <Route
          path='/card-order'
          render={({ match : { url } }) => {
            if (user && data.status === 0 && account) {
              return <>
                <Route exact path={`${url}`} component={CardOrderPage} />
                <Route exact path={`${url}/:cardOrderId/review`} component={CardOrderReviewPage} />
              </>
            }
            if (user && data.status === 0 && !account) {
              return <Redirect to='/role/account' />
            }
            if (!user) {
              return <Redirect to='/auth/signin' />
            }
          }}
        />
        <Route 
          path='/card-review'
          render={({ match: { url } }) => {
            if (user && data.status === 0 && account) {
              if (data.user_type_id === 1) {
                return <>
                  <Route exact path={`${url}`} component={CardReviewPage} />
                </>
              } else {
                return <Redirect to='/dashboard' />
              }
            }
            if (user && data.status === 0 && !account) {
              return <Redirect to='/role/account' />
            }
            if (!user) {
              return <Redirect to='/auth/signin' />
            }
          }}
        />
        <Route
          path='/card-order-batch'
          render={({ match : { url } }) => {
            if (user && data.status === 0 && account) {
              if (data.user_type_id === 1) {
                return <>
                  <Route exact path={`${url}`} component={CardOrderBatchPage} />
                  <Route exact path={`${url}/create`} component={CreateOrderBatchPage} />
                </>
              } else {
                return <Redirect to='/dashboard' />
              }
            }
            if (user && data.status === 0 && !account) {
              return <Redirect to='/role/account' />
            }
            if (!user) {
              return <Redirect to='/auth/signin' />
            }
          }}
        />
        <Route
          path='/not-found'
          component={() => (
            <h1 style={{ textAlign: 'center', height:'50%' }}>404 PAGE NOT FOUND</h1>
          )}
        />
        <Redirect to='/not-found' />
      </Switch>
    </Suspense>
  )
}

export default Routes

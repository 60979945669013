import AuthActionTypes from './auth.types'

const INITIAL_STATE = {
    loadingActivation: false,
    successActivation: false,
    errorActivation: false,

    loadingActivationAccount: false,
    successActivationAccount: false,
    errorActivationAccount: false,

    loadingCheckActivationAccount: false,
    successCheckActivationAccount: false,
    errorCheckActivationAccount: false,
    accountActivated: false,

    loadingResetPassword: false,
    successResetPassword: false,
    errorResetPassword: false,

    emailForgetPassword: null,
    forgetPasswordErrorMsg: null,

    loadingVerifyResetPassword: false,
    successVerifyResetPassword: false,
    errorVerifyResetPassword: false,

    token: '',
    role: 'auth',
    loading: false,
    error: false,
    success: '',
    account: null,
    user: null,
    status: 200,
    message: '',
    errorMessage: '',
};

const AuthReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AuthActionTypes.LOGIN:
            return {
                ...state,
                loading: true
            }
        case AuthActionTypes.VERIFY_ACCOUNT:
            return {
                ...state
            }
        case AuthActionTypes.LOGOUT:
            return {
                ...state,
                loading: false,
                error: false,
                success: 'logout',
                role: 'auth',
                token: '',
                user: null,
                account: null,
                status: 200
            }
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                token: action.payload.access_token,
                user: action.payload,
                success: 'login',
                status: 200,
                account: null
            }
        case AuthActionTypes.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: action.payload,
                message: action.message,
                success: false
            }
        case AuthActionTypes.VERIFY_ACCOUNT_FAILURE:
            return {
                ...state
            }
        case AuthActionTypes.LOGOUT_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                status: action.payload
            }
        case AuthActionTypes.VERIFY_ACCOUNT_SUCCESS:
            return {
                ...state,
                success: 'account',
                loading: false,
                error: false,
                role: 'admin',
                account: action.payload
            }
        case AuthActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                success: 'logout',
                role: 'auth',
                token: '',
                user: null,
                account: null,
                status: 200
            }
        case AuthActionTypes.VERIFIY_ACTIVATION:
            return {
                ...state,
                loadingActivation: true,
                successActivation: false,
            }
        case AuthActionTypes.VERIFY_ACTIVATION_SUCCESS:
            return {
                ...state,
                loadingActivation: false,
                successActivation: true,
                user: action.payload
            }
        case AuthActionTypes.VERFIY_ACTIVATION_FAILURE:
            return {
                ...state,
                loadingActivation: false,
                successActivation: false,
                errorActivation: true
            }
        case AuthActionTypes.ACTIVATION_ACCOUNT:
            return {
                ...state,
                loadingActivationAccount: true,
                successActivationAccount: false,
                errorActivationAccount: false,
                errorMessage: '',
            }
        case AuthActionTypes.ACTIVATION_ACCOUNT_SUCCESS:
            return {
                ...state,
                loadingActivationAccount: false,
                successActivationAccount: true,
                errorActivationAccount: false,
                errorMessage: '',
            }
        case AuthActionTypes.ACTIVATION_ACCOUNT_FAILURE:
            return {
                ...state,
                loadingActivationAccount: false,
                successActivationAccount: false,
                errorActivationAccount: true,
                errorMessage: action.payload,
            }
        case AuthActionTypes.ACTIVATION_ACCOUNT_SET_INITIAL:
            return {
                ...state,
                loadingActivationAccount: false,
                loadingCheckActivationAccount: false,
                accountActivated: false,
                successCheckActivationAccount: false,
                errorCheckActivationAccount: false,
                errorMessage: '',
            }
        case AuthActionTypes.CHECK_ACTIVATION_ACCOUNT:
            return {
                ...state,
                loadingCheckActivationAccount: true,
                successCheckActivationAccount: false,
                errorCheckActivationAccount: false,
                errorMessage: '',
            }
        case AuthActionTypes.CHECK_ACTIVATION_ACCOUNT_SUCCESS:
            return {
                ...state,
                loadingCheckActivationAccount: false,
                successCheckActivationAccount: true,
            }
        case AuthActionTypes.CHECK_ACTIVATION_ACCOUNT_ACTIVATED:
            return {
                ...state,
                accountActivated: true,
            }
        case AuthActionTypes.CHECK_ACTIVATION_ACCOUNT_FAILURE:
            return {
                ...state,
                loadingCheckActivationAccount: false,
                successCheckActivationAccount: false,
                errorCheckActivationAccount: true,
                errorMessage: action.payload,
            }
        case AuthActionTypes.RESET_PASSWORD:
            return {
                ...state,
                loadingResetPassword: true,
                successResetPassword: false
            }
        case AuthActionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loadingResetPassword: false,
                successResetPassword: true,
                emailForgetPassword: action.email
            }
        case AuthActionTypes.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loadingResetPassword: false,
                successResetPassword: false,
                errorResetPassword: true,
                forgetPasswordErrorMsg: action.payload
            }
        case AuthActionTypes.VERIFY_RESET_PASSWORD:
            return {
                ...state,
                loadingVerifyResetPassword: true,
                successVerifyResetPassword: false,
            }
        case AuthActionTypes.VERIFY_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loadingVerifyResetPassword: false,
                successVerifyResetPassword: true,
            }
        case AuthActionTypes.VERIFY_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loadingVerifyResetPassword: false,
                successVerifyResetPassword: false,
                errorVerifyResetPassword: true
            }
        default:
            return state
    }
};

export default AuthReducer;
  
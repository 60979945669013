import StateManager from "react-select";
import reportTypes from "./report.types";

const INITIAL_STATE = {
    isLoading: false,
    invoices: [],
    reportFormats: [
        {
            label: "Attach Comma-Separated Data File to Email",
            value: "C"
        },
        {
            label: "Attach Fixed-Width Data File to Email",
            value: "F"
        },
        {
            label: "No File",
            value: "N"
        }
    ],
    sampleFormats: [
        {
            label: "Comma-Separated File",
            value: "C"
        },
        {
            label: "Fixed-Width File",
            value: "F"
        }
    ],
    cardRanges: [{
        label: "All",
        value: "All"
    }],
    vehicleRanges: [{
        label: "All",
        value: "All"
    }],
    reports: [],
    exemptCards: [],
    isLoadingExempt: false
}

const ReportReducer = (state = INITIAL_STATE, action) => {
    switch(action.type){
        case reportTypes.GET_INVOICES:
            return{
                ...state,
                isLoading: true
            }
        case reportTypes.GET_INVOICES_SUCCESS:
            return{
                ...state,
                isLoading: false,
                invoices: action.payload.data
            }
        case reportTypes.GET_INVOICES_FAILURE:
            return{
                ...state,
                isLoading: false,
                invoices: []
            }
        case reportTypes.GET_CARD_RANGES:
            return{
                ...state,
                isLoading: true,
                cardRanges: [{
                    label: "All",
                    value: null
                }]
            }
        case reportTypes.GET_CARD_RANGES_SUCCESS:
            return{
                ...state,
                isLoading: false,
                cardRanges: state.cardRanges.concat(action.payload.sort((a, b) => parseFloat(a.label) - parseFloat(b.label)))
            }
        case reportTypes.GET_VEHICLE_RANGES:
            return{
                ...state,
                isLoading: true,
                vehicleRanges: [{
                    label: "All",
                    value: null
                }]
            }
        case reportTypes.GET_VEHICLE_RANGES_SUCCESS:
            return{
                ...state,
                isLoading: false,
                vehicleRanges: state.vehicleRanges.concat(action.payload.sort((a, b) => parseFloat(a.label) - parseFloat(b.label)))
            }
        case reportTypes.GET_VEHICLE_RANGES_FAILURE:
            return{
                ...state,
                isLoading: false,
                vehicleRanges: []
            }
        case reportTypes.CREATE_REPORT:
            return{
                ...state,
                isLoading: true
            }
        case reportTypes.CREATE_REPORT_SUCCESS:
            return{
                ...state,
                isLoading: false,
            }
        case reportTypes.CREATE_REPORT_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        case reportTypes.GET_REPORT:
            return{
                ...state,
                isLoading: true
            }
        case reportTypes.GET_REPORT_SUCCESS:
            return{
                ...state,
                isLoading: false,
                reports: action.payload
            }
        case reportTypes.GET_REPORT_FAILURE:
            return{
                ...state,
                isLoading: false
            }
        case reportTypes.GET_EXEMPT_CARDS:
            return{
                ...state,
                isLoadingExempt: true
            }
        case reportTypes.GET_EXEMPT_CARDS_SUCCESS:
            return{
                ...state,
                isLoadingExempt: false,
                exemptCards: action.payload
            }
        case reportTypes.GET_EXEMPT_CARDS_SUCCESS:
            return{
                ...state,
                isLoadingExempt: false,
                exemptCards: []
            }
        default: return state
    }
}

export default ReportReducer;
import { batch } from 'react-redux';
import axios from '../../axiosInstance'
import AccountActionTypes from '../account/account.types';
import  AuthActionTypes  from './auth.types';
import UserActionTypes from '../user/user.types';

const loginUrl = `/v1/login`
const loginSecretUrl = `/v1/secret_login`
const logoutUrl = `/v1/logout`
const verifyAccountUrl = '/v1/verify_account'
const updateTncUrl = '/v1/update_tnc'
const resetPasswordUrl = '/v1/password/forgot'
const verifyResetPasswordUrl = '/v1/password/reset'
const accountActivationUrl = '/v1/create_password'
const checkaccountActivationUrl = '/v1/check_activation_link?email='

export const login = (email, password) =>  {
    return async (dispatch) => {
      try {
        dispatch({
          type: AuthActionTypes.LOGIN
        })

      
        const res = await axios.post(
          loginUrl,
          {
            email,
            password
          }
        )
  
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: res.data.data
        })

      } catch (err) {
        dispatch({
          type: AuthActionTypes.LOGIN_FAILURE,
          payload: err && err.response && err.response.status,
          message: err.response.data.message
        })
      }
    }
  }

  export const loginSecret = (secret) =>  {
    return async (dispatch) => {
      try {
        dispatch({
          type: AuthActionTypes.LOGIN
        })

      
        const res = await axios.post(
          loginSecretUrl,secret
        )
  
        dispatch({
          type: AuthActionTypes.LOGIN_SUCCESS,
          payload: res.data.data
        })

      } catch (err) {
        dispatch({
          type: AuthActionTypes.LOGIN_FAILURE,
          payload: err && err.response && err.response.status,
          message: err.response.data.message
        })
      }
    }
  }



  
export const verifyAccount = (accountId) =>  {
  return async (dispatch, getState) => {

    const state = getState()
    const token = state.auth.token
    const userId = state.account.account.user_id
    const accounts = state.account.account.accounts
    const account = accounts.filter((item) => {
      return item.account_number === accountId;
    })

    try {
      dispatch({
        type: AuthActionTypes.VERIFY_ACCOUNT
      })

      const res = await axios.post(
        verifyAccountUrl,
        {
          user_id: userId
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
            AccountId: accountId
          }
        }
      )

      dispatch({
        type: AuthActionTypes.VERIFY_ACCOUNT_SUCCESS,
        payload: res.data.data
      })

    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({
        type: AuthActionTypes.VERIFY_ACCOUNT_FAILURE
      })
    }
  }
}

export const verifyActivation = () => {
   return async (dispatch, getState) => {

    const state = getState()
    const token = state.auth.token
    const userId = state.auth.user.id

    try {
      dispatch({
        type: AuthActionTypes.VERIFY_ACTIVATION
      })

      const res = await axios.post(
        updateTncUrl,
        {
         is_terms_condition: true
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
            userId
          }
        }
      )

      dispatch({
        type: AuthActionTypes.VERIFY_ACTIVATION_SUCCESS,
        payload: res.data.data
      })

    } catch (err) {
      dispatch({
        type: AuthActionTypes.VERIFY_ACTIVATION_FAILURE
      })
    }
  }
}

export const logout = () =>  {
  return async (dispatch, getState) => {
    const state = getState()
    const token = state.auth.token
  
    try {
      dispatch({
        type: AuthActionTypes.LOGOUT
      })
  
      // await axios.delete(logoutUrl, {
      //   headers: {
      //     Authorization: 'Bearer ' + token
      //   }
      // })

      batch(() => {
        dispatch({
          type: AuthActionTypes.LOGOUT_SUCCESS
        });
        dispatch({
          type: AccountActionTypes.DEFAULT_ACCOUNT
        });
      });

    } catch (err) {
      dispatch({
        type: AuthActionTypes.LOGOUT_FAILURE
      })
    }
  }
}

export const accountActivation = ({email, token, password, password_confirmation}) => {
  return async (dispatch, getState) => {
   try {
     dispatch({
       type: AuthActionTypes.ACTIVATION_ACCOUNT
     })

     const res = await axios.post(
      accountActivationUrl,
      {
        email,
        token,
        password,
        password_confirmation
      }
    )

     dispatch({
       type: AuthActionTypes.ACTIVATION_ACCOUNT_SUCCESS,
       payload: res.status
     })

     return res.status;

   } catch (err) {
     dispatch({
       type: AuthActionTypes.ACTIVATION_ACCOUNT_FAILURE,
       payload: err.response.data.message
     })
   }
 }
}

export const checkAccountActivation = (email) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: AuthActionTypes.CHECK_ACTIVATION_ACCOUNT
      })
      const res = await axios.get(
        checkaccountActivationUrl + encodeURIComponent(email)
      )

      dispatch({
        type: AuthActionTypes.CHECK_ACTIVATION_ACCOUNT_SUCCESS
      })

      if(!res.data){
        dispatch({
          type: AuthActionTypes.CHECK_ACTIVATION_ACCOUNT_ACTIVATED
        })
      }

    } catch (err) {
      dispatch({
        type: AuthActionTypes.CHECK_ACTIVATION_ACCOUNT_FAILURE,
        payload: err.response.data.message
      })
    }
  }
}


export const activationAccountSetInitial = () => {
  return (dispatch, getState) => {
    dispatch({
      type: AuthActionTypes.ACTIVATION_ACCOUNT_SET_INITIAL,
    });
  };
};

export const verifyResetPassword = ({email, token, password, password_confirmation}) => {
  return async (dispatch, getState) => {

   try {
     dispatch({
       type: AuthActionTypes.VERIFY_RESET_PASSWORD
     })

     const res = await axios.post(
       verifyResetPasswordUrl,
       {
         email,
         token,
         password,
         password_confirmation
       }
     )

     dispatch({
       type: AuthActionTypes.VERIFY_RESET_PASSWORD_SUCCESS,
       payload: res.status
     })

     return res.status;

   } catch (err) {
     dispatch({
       type: AuthActionTypes.VERIFY_RESET_PASSWORD_FAILURE
     })
   }
 }
}

export const resetPassword = (email) => {
  return async (dispatch, getState) => {

   try {
     dispatch({
       type: AuthActionTypes.RESET_PASSWORD
     })

     const res = await axios.post(
       resetPasswordUrl,
       {
         email,
         reseturl: process.env.REACT_APP_BASE_URL + '/auth/reset-password'
       }
     )

     dispatch({
       type: AuthActionTypes.RESET_PASSWORD_SUCCESS,
       payload: res.data.data,
       email: email
     })

     return res

   } catch (err) {
     batch(() => {
      dispatch({
        type: AuthActionTypes.RESET_PASSWORD_FAILURE,
        payload: err.response.data.message
      })
      dispatch({
        type: UserActionTypes.OPEN_POPUP,
        payload: {
          email: '',
          status: AuthActionTypes.RESET_PASSWORD_FAILURE,
          error: true
        }
      })
     })

     return err.response
   }
 }
}

export const tokenExpired = () =>  {
  return async (dispatch, getState) => {
  
    dispatch(logout())
    .then(() => {
      dispatch({
        type: UserActionTypes.OPEN_POPUP,
        payload: {
          status: 'token_expired',
          error: true
        }
      })
    })
  }
}





import UserActionTypes from './user.types'

const INITIAL_STATE = {
    users: {
      data: []
    },
    roles: [],

    loadingUsers: false,


    updateSuccess: false,
    updateLoading: false,


    user: null,
    loading: false,
    error: false,
    success: false,
    createLoading: false,
    userLoading: false,
    dataLoading: false,
    userTypes: [],
    roleTypes: [],
    roleTypesLoading: false,
    loadingRoles: false,

    showPopup: false,
    popupData: {
      email: '',
      status: 0,
      error: false,
      withActionButton: false,
      confirmAction: () => {},
    },

    showLoading: false
  };
  
  const UserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

      case UserActionTypes.CREATE_USER:
        return {
          ...state,
          createLoading: true,
          error: false,
        }

        case UserActionTypes.CREATE_USER_SUCCESS:
          return {
            ...state,
            createLoading: false,
            createSuccess: true,
            error: false,
            popupData: {
              email: action.payload.email,
              status: UserActionTypes.CREATE_USER_SUCCESS,
              error: false,
              withActionButton: false,
            },
            showPopup: true
          }

        case UserActionTypes.CREATE_USER_FAILURE:
          return {
            ...state,
            createLoading: false,
            createSuccess: false,
            error: true,
            popupData: {
              email: '',
              status: UserActionTypes.CREATE_USER_FAILURE,
              error: true,
              withActionButton: false,
            },
            showPopup: true
          }

        case UserActionTypes.UPDATE_USER:
          return {
            ...state,
            createLoading: true
          }

        case UserActionTypes.UPDATE_USER_SUCCESS:
            return {
              ...state,
              createLoading: false
            }

        case UserActionTypes.UPDATE_USER_FAILURE:
          return {
            ...state,
            createLoading: false
          }


      
        case UserActionTypes.GET_USERS:
          return {
            ...state,
            loadingUsers: true,
            updateLoading: false,
            updateSuccess: false,
            createLoading: false,
            createSuccess: false
          }  
          case UserActionTypes.GET_USERS_SUCCESS:
            return {
              ...state,
              loadingUsers: false,
              users: action.payload,
          }

          case UserActionTypes.GET_USERS_FAILURE:
            return {
              ...state,
              loadingUsers: false
            }


            

          case UserActionTypes.DELETE_USER:
            return {
              ...state,
              loading: true,
              error: false,
              updateSuccess: false
            }
            case UserActionTypes.GET_USER:
              return {
                ...state,
                userLoading: true,
                error: false,
                user: null,
                updateSuccess: false,
              }
          

            case UserActionTypes.GET_DATA:
                return {
                  ...state,
                  dataLoading: true,
                  error: false,
            }
            case UserActionTypes.DELETE_USER_SUCCESS:
              case UserActionTypes.GET_USER_SUCCESS:
                return {
                  ...state,
                  userLoading: false,
                  error: false,
                  user: action.payload
              }
          
              case UserActionTypes.GET_USER_TYPE_SUCCESS:
                return {
                  ...state,
                  dataLoading: false,
                  error: false,
                  userTypes: action.payload.userTypes
              }

              case UserActionTypes.GET_ROLES:
                return {
                  ...state,
                  loadingRoles: true
                }

              case UserActionTypes.GET_ROLES_SUCCESS:
                return {
                  ...state,
                  loadingRoles: false,
                  error: false,
                  roles: action.payload.roles
              }

              case UserActionTypes.GET_ROLES_FAILURE:
                return {
                  ...state,
                  loadingRoles: false,
                  error: false,
                  roles: []
              }

              case UserActionTypes.CLEAR_ROLES:
                return {
                  ...state,
                  roles: []
                }

              case UserActionTypes.GET_ROLE_TYPES:
                return {
                  ...state,
                  roleTypes: [],
                  roleTypesLoading: true
                }
              case UserActionTypes.GET_ROLE_TYPES_SUCCESS:
                return {
                  ...state,
                  roleTypes: action.payload,
                  roleTypesLoading: false
                }
              case UserActionTypes.GET_ROLE_TYPES_FAILURE:
                return {
                  ...state,
                  roleTypes: [],
                  roleTypesLoading: false
                }
        
          case UserActionTypes.GET_USER_FAILURE:
          case UserActionTypes.GET_DATA_FAILURE:
          case UserActionTypes.DELETE_USER_FAILURE:
              return {
                ...state,
                loading: false,
                error: true,
                createLoading: false,
                userLoading: false,
                updateLoading: false,
                dataLoading: false,
                roles: []
              }
          case UserActionTypes.UPDATE_STATUS:
          case UserActionTypes.UPDATE_STATUS_SUCCESS:
              return {
                ...state,
                showPopup: true,
                popupData: action.popupData,
              }
          case UserActionTypes.UPDATE_STATUS_FAILURE:
              return {
                ...state,
                showPopup: true,
                popupData: {
                  email: '',
                  status: UserActionTypes.UPDATE_STATUS_FAILURE,
                  error: true,
                  withActionButton: false,
                },
              }
          case UserActionTypes.RESEND_CONFIRMATION:
          case UserActionTypes.RESEND_CONFIRMATION_SUCCESS:
              return {
                ...state,
                showPopup: true,
                popupData: action.popupData,
              }
          case UserActionTypes.RESEND_CONFIRMATION_FAILURE:
              return {
                ...state,
                showPopup: true,
                popupData: {
                  email: '',
                  status: UserActionTypes.RESEND_CONFIRMATION_FAILURE,
                  error: true,
                  withActionButton: false,
                },
              }
          case UserActionTypes.CLOSE_POPUP:
              return {
                ...state,
                showPopup: false,
                popupData: null,
              }
          case UserActionTypes.OPEN_POPUP:
            return {
              ...state,
              showPopup: true,
              popupData: action.payload,
            }

          case UserActionTypes.SHOW_LOADING:
            return {
              ...state,
              showLoading: true
            }
          case UserActionTypes.HIDE_LOADING:
            return {
              ...state,
              showLoading: false
            }
          default: return state
    }
  };
  
  export default UserReducer;
  
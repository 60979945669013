import CardActionTypes from './card.types'

const INITIAL_STATE = {
    card: null,
    loadingCard: false,

    cards: {
      data: []
    },
    loadingCard: false,
    cardErrorMsg: {},
    loadingCreateCard: false,
    createCardSuccess: false,
    createCardData: null,
    uploadedCardData: null,

    loadingUpdateCard: false,
    loadingUpdateCardStatus: false,

    loadingDeleteCard: false,
    loadingBulkUploadCard: false,
    cardNetworks: [],
    cardTypes: [],
    cardCodes: [],

  };
  
  const CardReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
            case CardActionTypes.GET_CARDS:
              return {
                ...state,
                loadingCard: true,
                loadingCreateCard: false,
                createCardSuccess: false,
                cards: {
                  data: []
                } 
              }
            case CardActionTypes.GET_CARDS_SUCCESS:
              return {
                ...state,
                loadingCard: false,
                cards: action.payload
              }
            case CardActionTypes.GET_CARDS_FAILURE:
              return {
                ...state,
                loadingCard: false,
                cards: {
                  data: []
                } 
              }

            case CardActionTypes.GET_CARD:
              return {
                ...state,
                loadingCard: true
              }

            case CardActionTypes.GET_CARD_SUCCESS:
              return {
                ...state,
                card: action.payload,
                loadingCard: false
              }
            
            case CardActionTypes.GET_CARD_FAILURE:
              return {
                ...state,
                loadingCard: false
              }

            case CardActionTypes.CREATE_CARD:
              return {
                ...state,
                loadingCreateCard: true,
                createCardSuccess: false,
              }

            case CardActionTypes.CREATE_CARD_SUCCESS:
              return {
                ...state,
                createCardSuccess: true,
                loadingCreateCard: false,
                createCardData: action.payload,
              }
            
            case CardActionTypes.CREATE_CARD_FAILURE:
              return {
                ...state,
                createCardSuccess: false,
                loadingCreateCard: false,
                cardErrorMsg: action.payload
              }

          //   case CardTemplateActionTypes.GET_CARD_TEMPLATE:
          //     return {
          //       ...state,
          //       loadingCardTemplate: true,
          //     }
          //   case CardTemplateActionTypes.GET_CARD_TEMPLATE_SUCCESS:
          //     return {
          //       ...state,
          //       loadingCardTemplate: false,
          //       cardTemplate: action.payload
          //     }
          //   case CardTemplateActionTypes.GET_CARD_TEMPLATE_SUCCESS:
          //     return {
          //       ...state,
          //       loadingCardTemplate: false,
          //     }



            case CardActionTypes.CREATE_CARD_TEMPLATE:
              return {
                ...state,
                loadingCreateCard: true,
              }
            case CardActionTypes.CREATE_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                loadingCreateCard: false,
                createCardSuccess: true,
                createCardData: action.payload
              }
            case CardActionTypes.CREATE_CARD_TEMPLATE_SUCCESS:
              return {
                ...state,
                loadingCreateCard: false,
              }


          
            case CardActionTypes.UPDATE_CARD:
              return {
                ...state,
                loadingUpdateCard: true,
              }
            case CardActionTypes.UPDATE_CARD_SUCCESS:
              return {
                ...state,
                loadingUpdateCard: false,
              }
            case CardActionTypes.UPDATE_CARD_FAILURE:
              return {
                ...state,
                loadingUpdateCard: false,
                cardErrorMsg: action.payload
              }

            case CardActionTypes.UPDATE_CARD_STATUS:
              return {
                ...state,
                loadingUpdateCardStatus: true,
              }
            case CardActionTypes.UPDATE_CARD_STATUS_SUCCESS:
              return {
                ...state,
                loadingUpdateCardStatus: false,
              }

              case CardActionTypes.UPDATE_CARD_STATUS_FAILURE:
                return {
                  ...state,
                  loadingUpdateCardStatus: false,
                  cardErrorMsg: action.payload
                }
              
              case CardActionTypes.BULK_UPDATE_CARD:
                return {
                  ...state,
                  loadingCard: true
                }

              case CardActionTypes.BULK_UPDATE_CARD_SUCCESS:
                return {
                  ...state,
                  loadingCard: false
                }

              case CardActionTypes.BULK_UPDATE_CARD_FAILURE:
                return {
                  ...state,
                  loadingCard: false,
                  cardErrorMsg: action.payload
                }
              
              case CardActionTypes.BULK_UPLOAD_CARD:
                return {
                  ...state,
                  loadingBulkUploadCard: true
                }
              case CardActionTypes.BULK_UPLOAD_CARD_SUCCESS:
                return {
                  ...state,
                  loadingBulkUploadCard: false,
                  uploadedCardData: action.payload
                }
              case CardActionTypes.BULK_UPLOAD_CARD_FAILURE:
                return {
                  ...state,
                  loadingBulkUploadCard: false,
                  cardErrorMsg: action.payload
                }
              case CardActionTypes.REMOVE_UPLOADED_CARD_DATA:
                return {
                  ...state,
                  uploadedCardData: null
                }


          //  case CardTemplateActionTypes.DELETE_CARD_TEMPLATE:
          //     return {
          //       ...state,
          //       loadingDeleteCardTemplate: true,
          //     }
          //   case CardTemplateActionTypes.DELETE_CARD_TEMPLATE_SUCCESS:
          //     return {
          //       ...state,
          //       loadingDeleteCardTemplate: false,
          //     }
          //   case CardTemplateActionTypes.DELETE_CARD_TEMPLATE_SUCCESS:
          //     return {
          //       ...state,
          //       loadingDeleteCardTemplate: false,
          //     }

          //   case CardTemplateActionTypes.GET_EDIT_CARD_TEMPLATE_SUCCESS:
          //     return {
          //       ...state,
          //       cardNetworks: action.payload.cardNetworks,
          //       cardCodes: action.payload.cardCodes,
          //       cardTypes: action.payload.cardTypes
          //     }

          //   case CardTemplateActionTypes.GET_EDIT_CARD_TEMPLATE_FAILURE:
          //     return {
          //       ...state
          //     }
          
          default: return state
    }
  };
  
  export default CardReducer;
  
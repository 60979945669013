import axios from '../../axiosInstance'
import  UserActionTypes  from './user.types';
import { batch } from "react-redux";
import { tokenExpired } from '../authentication/auth.actions'

const userUrl = `/v1/get_all_user_by_account`;
const userByIdUrl = '/v1/users/';
const createUserUrl = '/v1/add_user';
const petrocardRoleUrl = "/v1/get_user_roles";
const userTypeUrl = '/v1/get_user_types';
const roleTypeUrl = '/v1/get_type_roles'
const deleteUserUrl = '/v1/user_delete/';
const updateStatusUrl = '/v1/users/update_status/';
const updateForceStatusUrl = '/v1/users/force_tnc/';
const resendConfirmUrl = '/v1/resend_confirmation/';

export const getUsers = ({keyword= null, page=1, orderBy = null, sortedBy = null}) =>  {
  return async (dispatch, getState) => {
    const state = getState()
    const token = state.auth.token
    const account = state.auth.account
  
    try {
      dispatch({
        type: UserActionTypes.GET_USERS
      })
      
      const res = await axios.get(
        userUrl,
        {
          params: { keyword, page, orderBy, sortedBy },
          headers: {
            Authorization: 'Bearer ' + token,
            AccountId: account.account.id
          }
        }
      )


      dispatch({
        type: UserActionTypes.GET_USERS_SUCCESS,
        payload: res.data,
      })

    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({
        type: UserActionTypes.GET_USERS_FAILURE
      })
    }
  }
}

export const getUser = (userId) =>  {
  return async (dispatch, getState) => {
    const state = getState()
    const token = state.auth.token
    const account = state.auth.account

    try {
      dispatch({
        type: UserActionTypes.GET_USER
      })

    
      const res = await axios.get(
        `${userByIdUrl}${userId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            AccountId: account.account.id
            
          }
        }
      )     
   
      dispatch({
        type: UserActionTypes.GET_USER_SUCCESS,
        payload: res.data.data
      })

      return res

    } catch (err) {
      if (err.response.status === 401) {
        dispatch(tokenExpired())
      }
      dispatch({
        type: UserActionTypes.GET_USER_FAILURE
      })
      return err.response
    }
  }
}

export const createUser = (user) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: UserActionTypes.CREATE_USER
        })

        user.activationurl =  process.env.REACT_APP_BASE_URL + '/auth/account-activation';

      
        const res = await axios.post(
          createUserUrl,
          user,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )
        
        batch(() => {
          dispatch({
            type: UserActionTypes.CREATE_USER_SUCCESS,
            payload: res.data.data
          })
        })

        
        return res;

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: UserActionTypes.CREATE_USER_FAILURE
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              status: UserActionTypes.CREATE_USER_FAILURE,
              error: true
            }
          });
        })
        return err.response
      }
    }
  }

  export const updateUser = (user, userId) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account
      // const user_type= state.auth.user.user_type_id
      
      // user.user_type = user_type;

      try {
        dispatch({
          type: UserActionTypes.UPDATE_USER
        })

      
        const res = await axios.put(
          `${userByIdUrl}${userId}`,
          user,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )

        batch(() => {
          dispatch({
            type: UserActionTypes.UPDATE_USER_SUCCESS,
            payload: res.data.data
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              status: UserActionTypes.UPDATE_USER_SUCCESS,
              error: false
            }
          });
        })

        return res

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        batch(() => {
          dispatch({
            type: UserActionTypes.UPDATE_USER_FAILURE
          })
          dispatch({
            type: UserActionTypes.OPEN_POPUP,
            payload: {
              status: UserActionTypes.UPDATE_USER_FAILURE,
              error: true
            }
          });
        })
        if(err.response.status === 422){
          return err.response.data.errors
        }
        
        return err.response
      }
    }
  }
  
  
  export const getUserType = () =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
          const userTypes = await axios.get(
            userTypeUrl,
            {
              headers: {
                Authorization: 'Bearer ' + token,
                UserTypeId: state.auth.user.user_type_id,
              }
            }
          )

        // const roles = await axios.get(
        //   petrocardRoleUrl,
        //   {
        //     headers: {
        //       Authorization: 'Bearer ' + token,
        //       UserTypeId: state.auth.user.user_type_id,
        //       AccountId: account.account.id
        //     }
        //   }
        // )
        let listUserTypes = userTypes.data.data
        listUserTypes.map((type, i) => {
          if (type.name.toLowerCase() === 'petrocard') {
            listUserTypes[i].name = 'PetroCard User'
          }
        })
        dispatch({
          type: UserActionTypes.GET_USER_TYPE_SUCCESS,
          payload: {
            userTypes: listUserTypes
          }
        })

        return userTypes

      } catch (err) {
        dispatch({
          type: UserActionTypes.GET_USER_TYPE_FAILURE
        })
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
      }
    }
  }

  export const getRoles = (UserTypeId= null, RoleTypeId = null) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {

        dispatch({
            type: UserActionTypes.GET_ROLES
        })

        const roles = await axios.get(
          petrocardRoleUrl,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              UserTypeId: UserTypeId || state.auth.user.user_type_id,
              RoleTypeId: RoleTypeId,
              AccountId: account.account.id
            }
          }
        )
  
        dispatch({
          type: UserActionTypes.GET_ROLES_SUCCESS,
          payload: {
            roles: roles.data.data,
          }
        })

        return roles

      } catch (err) {
        dispatch({
          type: UserActionTypes.GET_ROLES_FAILURE
        })
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        return err.response
      }
    }
  }

  export const clearRoles = () =>  {
    return async (dispatch) => {
      dispatch({
          type: UserActionTypes.CLEAR_ROLES
      })
    }
  }  

  export const getRoleTypes = (userType = null) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {

        dispatch({
            type: UserActionTypes.GET_ROLE_TYPES
        })

        const response = await axios.get(
          roleTypeUrl,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              // UserTypeId: user_type || state.auth.user.user_type_id,
              AccountId: account.account.id
            }
          }
        )

        let listRoleType = []
        if (userType === 1) {
          listRoleType.push(
            {
              label: 'PetroCard User',
              value: 1
            }
          )
        } else {
          for (var key in response.data) {
            if (key !== '1') {
              listRoleType.push(
                {
                  label: response.data[key],
                  value: key
                }
              )
            }
          }
        }
  
        dispatch({
          type: UserActionTypes.GET_ROLE_TYPES_SUCCESS,
          payload: listRoleType
        })

        return listRoleType

      } catch (err) {
        dispatch({
          type: UserActionTypes.GET_ROLE_TYPES_FAILURE
        })
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        return err.response
      }
    }
  }
  
  export const deleteUser = ({userId= null}) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      const account = state.auth.account

      try {
        dispatch({
          type: UserActionTypes.DELETE_USER
        })

      
        const res = await axios.delete(
          `${deleteUserUrl}${userId}`,
          {
            headers: {
              Authorization: 'Bearer ' + token,
              AccountId: account.account.id
            }
          }
        )

       getUsers({search: null})
  
        dispatch({
          type: UserActionTypes.DELETE_USER_SUCCESS,
          payload: res.data.data
        })

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: UserActionTypes.DELETE_USER_FAILURE
        })
      }
    }
  }

  export const closePopup = () => {
    return (dispatch, getState) => {
      dispatch({
        type: UserActionTypes.CLOSE_POPUP,
      });
    };
  };

  export const openPopup = (popupData) => {
    return (dispatch, getState) => {
      dispatch({
        type: UserActionTypes.OPEN_POPUP,
        payload: popupData,
      });
    }
  }
  
  export const updateStatus = ({ user_id = '', email = null, status = null }, type) => {
    return async (dispatch, getState) => {
      const state = getState();
      const token = state.auth.token;
      const account = state.account.account.accounts[0];
  
      try {
        dispatch({ type: UserActionTypes.UPDATE_STATUS });

        let res = null;

        if(type === 'force'){
           res = await axios.put(`${updateForceStatusUrl}${user_id}`, {}, {
            headers: {
              Authorization: "Bearer " + token,
              AccountId: account.account_id,
              Accept: "application/json",
              Status: status,
              "Content-Type": "application/json",
            },
          });
        } else {
           res = await axios.put(`${updateStatusUrl}${user_id}`, {}, {
            headers: {
              Authorization: "Bearer " + token,
              AccountId: account.account_id,
              Accept: "application/json",
              Status: status,
              "Content-Type": "application/json",
            },
          });
        }
  
  
        dispatch({
          type: UserActionTypes.UPDATE_STATUS_SUCCESS,
          popupData: {email: email, status: status, error: false,withActionButton: false},
          payload: res,
        });
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({ type: UserActionTypes.UPDATE_STATUS_FAILURE });
      }
    };
  };

  export const resendInvitation = ({ user_id = '', email = null, status = null}) => {
    return async (dispatch, getState) => {
      const state = getState();
      const {token} = state.auth;
      const account = state.account.account.accounts[0];

      try {
        dispatch({ type: UserActionTypes.RESEND_CONFIRMATION })

        const res = await axios.get(`${resendConfirmUrl}${user_id}`, {
          headers: {
            Authorization: "Bearer " + token,
            AccountId: account.account_id
          }
        });

        dispatch({
          type: UserActionTypes.RESEND_CONFIRMATION_SUCCESS,
          popupData: {email: email, status: status, error: false},
          payload: res
        });
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({ type: UserActionTypes.RESEND_CONFIRMATION_FAILURE });
      }
    }
  }

  export const showLoading = (loadingStatus) => {
    return (dispatch, getState) => {
      if (loadingStatus) {
        dispatch({
          type: UserActionTypes.SHOW_LOADING,
        });
      } else {
        dispatch({
          type: UserActionTypes.HIDE_LOADING,
        });
      }
    }
  }
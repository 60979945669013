import EditProfileActionTypes from './edit-profile.types'

const INITIAL_STATE = {
    get_profile: {
        data: []
    },
    profile: {
        data: []
    },
    password: {
        data: []
    },

    loadingProfile: false,

    loadingEditProfile: false,
    loadingEditPassword: false,

    updateProfileSuccess: false,
    updatePasswordSuccess: false,

    error: false,
};

const EditProfileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case EditProfileActionTypes.SET_MESSAGE_UPDATE_OFF:
            return {
                ...state,
                updateProfileSuccess: false,
                updatePasswordSuccess: false,
            }

        case EditProfileActionTypes.GET_PROFILE:
            return {
                ...state,
                loadingProfile: true,
                error: false,
            }
        case EditProfileActionTypes.GET_PROFILE_SUCCESS:
            return {
                ...state,
                loadingProfile: false,
                get_profile: action.payload,
            }
        case EditProfileActionTypes.GET_PROFILE_FAILURE:
            return {
                ...state,
                loadingProfile: false,
                error: true
            }

        case EditProfileActionTypes.UPDATE_PROFILE:
            return {
                ...state,
                loadingEditProfile: true,
                updateProfileSuccess: false,
                error: false,
            }
        case EditProfileActionTypes.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                loadingEditProfile: false,
                updateProfileSuccess: true,
                get_profile: {data: action.payload.data},
                error: false,
            }
        case EditProfileActionTypes.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                loadingEditProfile: false,
                updateProfileSuccess: false,
                error: action.payload
            }

        case EditProfileActionTypes.UPDATE_PASSWORD:
            return {
                ...state,
                loadingEditPassword: true,
                updatePasswordSuccess: false,
                error: false,
            }
        case EditProfileActionTypes.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loadingEditPassword: false,
                updatePasswordSuccess: true,
                error: false,
            }
        case EditProfileActionTypes.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                loadingEditPassword: false,
                updatePasswordSuccess: false,
                error: action.payload
            }

        default:
            return state
    }
};

export default EditProfileReducer;
  
import DeliveryActionTypes from "./delivery-address.types";
import BillingActionTypes from './billing-information.types';

const INITIAL_STATE = {
  data: [],
  loadingData: false,
  loading: false,
  showPopup: false,
  isEmpty: false,
  createSuccess: false,
  editSuccess: false,

  billingInformation: {},
  loadingBillingInformation: false,
  stateOption: [],
};

const DeliveryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeliveryActionTypes.CREATE_DELIVERY:
      return {
        ...state,
        loading: true,
      };

    case DeliveryActionTypes.CREATE_DELIVERY_SUCCESS:
      return {
        ...state,
        loading: false,
        createSuccess: true,
        // data: [...state.data, action.payload]
      };

    case DeliveryActionTypes.CREATE_DELIVERY_FAILURE:
      return {
        ...state,
        loading: false,
        createLoading: false,
        createSuccess: false,
        error: true
     };
    case DeliveryActionTypes.GET_DATA:
      return {
        ...state,
        loadingData: true,
      }
    case DeliveryActionTypes.GET_DATA_SUCCESS:
      return {
        ...state,
        loadingData: false,
        data: action.payload.data,
        isEmpty: false,
      }
    case DeliveryActionTypes.GET_DATA_FAILURE:
      return {
        ...state,
        loadingData: false,
        data: [],
        isEmpty: true,
      }

      case BillingActionTypes.GET_BILLING_INFORMATION:
        return {
          ...state,
          loadingBillingInformation: true,
        }
      case BillingActionTypes.GET_BILLING_INFORMATION_SUCCESS:
        return {
          ...state,
          loadingBillingInformation: false,
          billingInformation: action.payload
        }
      case BillingActionTypes.GET_BILLING_INFORMATION_FAILURE:
        return {
          ...state,
          loadingBillingInformation: false
        }

        case BillingActionTypes.UPDATE_BILLING_INFORMATION:
          return {
            ...state,
            loadingBillingInformation: true,
          }
        case BillingActionTypes.UPDATE_BILLING_INFORMATION_SUCCESS:
          return {
            ...state,
            loadingBillingInformation: false,
            billingInformation: action.payload
          }
        case BillingActionTypes.UPDATE_BILLING_INFORMATION_FAILURE:
          return {
            ...state,
            loadingBillingInformation: false
          }
          
        case DeliveryActionTypes.GET_STATE_OPTION:
          return {
            ...state,
            stateOption: []
          }
        case DeliveryActionTypes.GET_STATE_OPTION_SUCCESS:
          return {
            ...state,
            stateOption: action.payload,
          }
        case DeliveryActionTypes.GET_STATE_OPTION_FAILURE:
          return {
            ...state,
            stateOption: []
          }

        case DeliveryActionTypes.UPDATE_DELIVERY:
          return {
            ...state,
            loading: true,
          };
        case DeliveryActionTypes.UPDATE_DELIVERY_SUCCESS:
          return {
            ...state,
            loading: false,
            editSuccess: true,
            // data: [...state.data, action.payload]
          };
        case DeliveryActionTypes.UPDATE_DELIVERY_FAILURE:
          return {
            ...state,
            loading: false,
            createLoading: false,
            editSuccess: false,
            error: true
          }

        case DeliveryActionTypes.DELETE_DELIVERY:
          return {
            ...state,
            loading: true
          }

        case DeliveryActionTypes.DELETE_DELIVERY_SUCCESS:
          return {
            ...state,
            loading: false
          }

          case DeliveryActionTypes.DELETE_DELIVERY_FAILURE: 
          return {
            ...state,
            loading: false
          }
          
        default:
          return state;
  }
};

export default DeliveryReducer;

const CardActionTypes = {
    CREATE_CARD: "CREATE_CARD",
    CREATE_CARD_SUCCESS: "CREATE_CARD_SUCCESS",
    CREATE_CARD_FAILURE: "CREATE_CARD_FAILURE",
    UPDATE_CARD: "UPDATE_CARD",
    UPDATE_CARD_SUCCESS: "UPDATE_CARD_SUCCESS",
    UPDATE_CARD_FAILURE: "UPDATE_CARD_FAILURE",
    GET_CARDS: "GET_CARDS",
    GET_CARDS_SUCCESS: "GET_CARDS_SUCCESS",
    GET_CARDS_FAILURE: "GET_CARDS_FAILURE",
    GET_CARD: "GET_CARD",
    GET_CARD_SUCCESS: "GET_CARD_SUCCESS",
    GET_CARD_FAILURE: "GET_CARD_FAILURE",
    GET_EDIT_CARD: "GET_EDIT_CARD",
    GET_EDIT_CARD_SUCCESS: "GET_EDIT_CARD_SUCCESS",
    GET_EDIT_CARD_FAILURE: "GET_EDIT_CARD_FAILURE",
    DELETE_CARD: "DELETE_CARD",
    DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
    DELETE_CARD_FAILURE: "DELETE_CARD_FAILURE",
    UPDATE_CARD_STATUS: "UPDATE_CARD_STATUS",
    UPDATE_CARD_STATUS_SUCCESS: "UPDATE_CARD_STATUS_SUCCESS",
    UPDATE_CARD_STATUS_FAILURE: "UPDATE_CARD_STATUS_FAILURE",
    BULK_UPDATE_CARD: "BULK_UPDATE_CARD",
    BULK_UPDATE_CARD_SUCCESS: "BULK_UPDATE_CARD_SUCCESS",
    BULK_UPDATE_CARD_FAILURE: "BULK_UPDATE_CARD_FAILURE",
    BULK_UPLOAD_CARD: "BULK_UPLOAD_CARD",
    BULK_UPLOAD_CARD_SUCCESS: "BULK_UPLOAD_CARD_SUCCESS",
    BULK_UPLOAD_CARD_FAILURE: "BULK_UPLOAD_CARD_FAILURE",
    REMOVE_UPLOADED_CARD_DATA: "REMOVE_UPLOADED_CARD_DATA"
  };
  
  export default CardActionTypes;
  
import axios from '../../axiosInstance'
import  AccountActionTypes  from './account.types';
import { tokenExpired } from '../authentication/auth.actions'

const accountUrl = `/v1/accounts_user`

export const getAccount = (keyword = '', param = '') =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      try {
        dispatch({
          type: AccountActionTypes.GET_ACCOUNT
        })

      
        const res = await axios.get(
          `${accountUrl}?keyword=${keyword}&${param}`,
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
  
        dispatch({
          type: AccountActionTypes.GET_ACCOUNT_SUCCESS,
          payload: res.data.data
        })

        return res

      } catch (err) {
        if (err.response.status === 401) {
          dispatch(tokenExpired())
        }
        dispatch({
          type: AccountActionTypes.GET_ACCOUNT_FAILURE
        })
        return err.response
      }
    }
  }


export const resetPassword = (email) =>  {
    return async (dispatch, getState) => {
      const state = getState()
      const token = state.auth.token
      try {
        dispatch({
          type: AccountActionTypes.RESET_PASSWORD
        })

        const res = await axios.post(
          accountUrl,
          {
            email
          },
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }
        )
  
        dispatch({
          type: AccountActionTypes.RESET_PASSWORD_SUCCESS,
          payload: res.data.data
        })

      } catch (err) {
        dispatch({
          type: AccountActionTypes.RESET_PASSWORD_FAILURE
        })
      }
    }
  }




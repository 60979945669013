import React from 'react';
import "./footer.styles.scss";
import { IconContext } from 'react-icons';
import { BsExclamationCircleFill } from 'react-icons/bs';

const Footer = () => (
    <div>
        <div className="customer-service-container">
            <IconContext.Provider
                value={{ color: '#ef5350', size: '20px' }}
            >
                <div>
                    <BsExclamationCircleFill />
                </div>
            </IconContext.Provider>
            <span className="customer-service-info">For lost or stolen cards, immediately call Customer Service at 1.800.950.3835 (24 hours a day).</span>
        </div>
        <div className="footer-info">
            <span>COPYRIGHT ©2021 PETROCARD, INC. ALL RIGHTS RESERVED.</span>
        </div>
    </div>
);

export default Footer;

import React from 'react';
import { IconContext } from "react-icons";
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai'

const Order = ({ data }) => {
    const { status, active, order } = data;
    if (status === "down") {
        return <IconContext.Provider
            value={{ color: (active && status === 'down' && '#ff0100') || '#ffffff', size: data.size }}
        >
            <i style={{ cursor: 'pointer' }} onClick={order}>
                <AiFillCaretDown />
            </i>
        </IconContext.Provider>
    } else {
        return <IconContext.Provider
            value={{ color: (active && status === 'up' && '#ff0100') || '#ffffff', size: data.size }}
        >
            <i style={{ cursor: 'pointer' }} onClick={order}>
                <AiFillCaretUp />
            </i>
        </IconContext.Provider>
    }

}

export default Order;
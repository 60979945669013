import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Row, Col, Form, Modal, Card, Spinner } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import Select from 'react-select';
import NumberFormat from "react-number-format";

import "./card-management.styles.scss";

const SecondStep = ({ handleNext, backStep, deliveryAddress, isVirtualCard }) => {
  const { statesOption } = useSelector((state) => state.cardTemplate)
  const { data } = useSelector(state => state.deliveryAddress);
  const { user } = useSelector((state) => state.auth)
  const { account } = useSelector((state) => state.account)
  const { loadingCreateCard } = useSelector(state => state.card)
  const [ initDeliveryAddress, setInitDeliveryAddress ] = useState({...deliveryAddress})
  const [ shippingMethod, setShippingMethod ] = useState("")
  const [ selectedAddress, setSelectedAddress ] = useState(
    {
      label : 'New Address Below',
      value : {
        id: '',
        address: '',
        city: '',
        zipcode: ''
      }
    }
  )
  const [ selectedState, setSelectedState ] = useState(
    {
      label: '',
      value: ''
    }
  )
  const [ deliveryError, setDeliveryError ] = useState({})

  useEffect(() => {
    if (account !== null) {
      if (user.user_type_id === 2) {
        setInitDeliveryAddress({...initDeliveryAddress, requestor: account.name})
      }
    }
  }, [])

  const addressList = () => {
    let tempAddressList = []
    tempAddressList.push(
      {
        label : 'New Address Below',
        value : {
          id: '',
          address: '',
          city: '',
          zipcode: ''
        }
      }
    )
    data.map(deliveryAddress => {
      tempAddressList.push(
        {
          label: deliveryAddress.address_name,
          value: deliveryAddress
        }
      )
    })
    return tempAddressList
  }

  const statesSorted = (listState) => {
    let tempStatesSorted = listState.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
          return -1;
      }
      if (fa > fb) {
          return 1;
      }
      return 0;
    })
    tempStatesSorted.map((state, index) => {
      if (state.name === 'All States (ALL)') {
        tempStatesSorted.splice(index, 1)
      }
    })
    return tempStatesSorted
  }

  const changeDeliveryMethod = (deliveryMethod) => {
    let tempInitDeliveryAddress = {...initDeliveryAddress}
    if (deliveryMethod === 'address') {
      tempInitDeliveryAddress.pickup_address = "730 Central Ave S Kent, WA 98032"
      tempInitDeliveryAddress.pickup_name = ""
      tempInitDeliveryAddress.pickup_phone_number = ""
      tempInitDeliveryAddress.pickup_special_instruction = ""
      tempInitDeliveryAddress.is_delivery = true
      tempInitDeliveryAddress.create_new_address = true
    } else {
      tempInitDeliveryAddress.delivery_address_name = ""
      tempInitDeliveryAddress.delivery_attention_to = ""
      tempInitDeliveryAddress.delivery_city = ""
      tempInitDeliveryAddress.delivery_special_instruction = ""
      tempInitDeliveryAddress.delivery_state = ""
      tempInitDeliveryAddress.delivery_street_address = ""
      tempInitDeliveryAddress.delivery_street_address_secondary = ""
      tempInitDeliveryAddress.delivery_zipcode = ""
      tempInitDeliveryAddress.create_new_address = false
      tempInitDeliveryAddress.is_delivery = false
      setSelectedAddress({...addressList()[0]})
      setSelectedState(
        {
          label: '',
          value: ''
        }
      )
    }
    setInitDeliveryAddress({...tempInitDeliveryAddress})
  }

  const selectAddress = (address) => {
    setSelectedAddress({...address});
    let tempInitDeliveryAddress = {...initDeliveryAddress}
    tempInitDeliveryAddress.delivery_address_name = address.value.id !== '' ? address.value.address_name : ''
    tempInitDeliveryAddress.delivery_attention_to = address.value.id !== '' ? address.value.attention_to : ''
    tempInitDeliveryAddress.delivery_city = address.value.id !== '' ? address.value.city : ''
    tempInitDeliveryAddress.delivery_state = address.value.id !== '' ? address.value.state : ''
    tempInitDeliveryAddress.delivery_street_address = address.value.id !== '' ? address.value.address : ''
    tempInitDeliveryAddress.delivery_street_address_secondary = address.value.id !== '' ? address.value.address_secondary : ''
    tempInitDeliveryAddress.delivery_zipcode = address.value.id !== '' ? address.value.zipcode : ''
    tempInitDeliveryAddress.delivery_phone_number = address.value.id !== '' ? address.value.phone: ''
    if (address.value.id !== '') {
      tempInitDeliveryAddress.create_new_address = false
      let stateIndex = statesOption.findIndex(x => x.id === address.value.state)
      setSelectedState(
        {
          label: stateIndex !== -1 ? statesOption[stateIndex].name : '',
          value: stateIndex !== -1 ? statesOption[stateIndex].id : ''
        }
      )
    } else {
      tempInitDeliveryAddress.create_new_address = true
      setSelectedState(
        {
          label: '',
          value: ''
        }
      )
    }
    setInitDeliveryAddress({...tempInitDeliveryAddress})
  }

  const selectState = (state) => {
    setSelectedState({...state})
    setInitDeliveryAddress({...initDeliveryAddress, delivery_state: state.value})
  }

  const deliveryAddressValidation = () => {
    let status = true
    let errors = {}
    let validation = ''
    let listDeliveryValidation = {
      delivery_address_name: 'Address name field is required',
      // delivery_attention_to: 'Attention to field is required',
      delivery_city: 'City field is required',
      delivery_state: 'State field is required',
      delivery_street_address: 'Street address field is required',
      delivery_zipcode: 'Zip Code field is required'
    }
    let pickupValidation = {
      pickup_name: 'Name field is required',
      pickup_phone_number: 'Phone number field is required'
    }
    if (initDeliveryAddress.is_delivery) {
      validation = listDeliveryValidation
    } else {
      validation = pickupValidation
    }
    for (var key in validation) {
      if (initDeliveryAddress[key] === '') {
        status = false
        errors[key] = validation[key]
      }
    }
    setDeliveryError({...errors})
    return status
  }

  const nextStep = () => {
    if (isVirtualCard) {
      handleNext(initDeliveryAddress)
    } else {
      if (deliveryAddressValidation()) {
        handleNext(initDeliveryAddress)   
      }
    }
  }

  const customOptionLabel = ({ value, label }) => (
    <div>
      <div>{label}</div>
      {
        value.id !== '' && <div style={{fontSize: "12px", color: "#888888"}}>{value.address} - {value.city} - {value.zipcode}</div>
      }
    </div>
  );

  const shippingMethods = [
    {
      label: "USPS",
      value: "USPS"
    },
    {
      label: "UPS Overnight",
      value: "UPS Overnight"
    },
    {
      label: "UPS Ground",
      value: "UPS Ground"
    }
  ]

  return (
    <Card.Body className="card-delivery-address-container">
      {
        isVirtualCard ?
        <div className="virtual-card-description">
          <p>Your request has been submitted to Customer Service.</p>
          <p>You will receive an email with a case number to track the progress of your request. Once completed, another email will be sent to you closing the case. You will then be able to download an updated version of the card list to view your request.</p>
        </div>
        :
        <div>
          <div className="option-delivery mt-2 mb-5">
            <button
              className={`delivery-button mr-3 ${initDeliveryAddress.is_delivery === true ? "selected" : ""}`}
              onClick={() => {
                changeDeliveryMethod("address");
              }}
            >
              <IconContext.Provider
                value={{ color: initDeliveryAddress.is_delivery === true ? '#6DD084' : '#CED4DA'}}
              >
                <FaCheckCircle />
              </IconContext.Provider>
              Delivery to My Address
            </button>
            <button
              className={`delivery-button ${initDeliveryAddress.is_delivery === false ? "selected" : ""}`}
              onClick={() => {
                changeDeliveryMethod("office");
              }}
            >
              <IconContext.Provider
                value={{ color: initDeliveryAddress.is_delivery === false ? '#6DD084' : '#CED4DA'}}
              >
                <FaCheckCircle />
              </IconContext.Provider>
              Pickup at PetroCard Kent, WA Office
            </button>
          </div>
          {
            initDeliveryAddress.is_delivery
            ? <div>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group>
                    <Form.Label className="input-label title-form">
                      Please select from the address below or add a new one.
                    </Form.Label>
                    <Select
                      placeholder="Select Address"
                      value={selectedAddress}
                      onChange={(address) => {
                        selectAddress(address)
                      }}
                      components={{
                        IndicatorSeparator: () => null
                      }}
                      styles={{
                        option: (styles, { isDisabled, isSelected }) => {
                          return {
                            ...styles,
                            backgroundColor: isSelected && "#ff0000",
                          };
                        },
                        valueContainer: styles => ({...styles, padding: '12px 12px'})
                      }}
                      options={[...addressList()].map(address => {
                          return {
                            label: address.label,
                            value: address.value
                          }
                        })
                      }
                      formatOptionLabel={customOptionLabel}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div style={{ background: "#f8f8f8", padding: "20px" }}>
                {
                  selectedAddress.value.id === '' &&
                  <Row>
                    <Col lg={6} md={6}>
                      <h5 className="text-danger font-weight-bold">ADD NEW ADDRESS</h5>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group>
                      <Form.Label className="title-form">Address Name</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Address Name"
                        value={initDeliveryAddress.delivery_address_name}
                        isInvalid={deliveryError.hasOwnProperty('delivery_address_name')}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, delivery_address_name: e.target.value})}
                      />
                      <div className="invalid-feedback">{deliveryError.delivery_address_name}</div>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group>
                      <Form.Label className="title-form">Attention To</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Attention To"
                        value={initDeliveryAddress.delivery_attention_to}
                        isInvalid={deliveryError.hasOwnProperty('delivery_attention_to')}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, delivery_attention_to: e.target.value})}
                      />
                      <div className="invalid-feedback">{deliveryError.delivery_attention_to}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group>
                      <Form.Label className="title-form">Address</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Street Address"
                        value={initDeliveryAddress.delivery_street_address}
                        isInvalid={deliveryError.hasOwnProperty('delivery_street_address')}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, delivery_street_address: e.target.value})}
                      />
                      <div className="invalid-feedback">{deliveryError.delivery_street_address}</div>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group>
                      <Form.Label className="title-form">City</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="City"
                        value={initDeliveryAddress.delivery_city}
                        isInvalid={deliveryError.hasOwnProperty('delivery_city')}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, delivery_city: e.target.value})}
                      />
                      <div className="invalid-feedback">{deliveryError.delivery_city}</div>
                    </Form.Group>
                    {/* <Form.Group>
                      <Form.Label className="title-form">Address 2</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Street Address"
                        value={initDeliveryAddress.delivery_street_address_secondary}
                        isInvalid={deliveryError.hasOwnProperty('delivery_street_address_secondary')}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, delivery_street_address_secondary: e.target.value})}
                      />
                      <div className="invalid-feedback">{deliveryError.delivery_street_address_secondary}</div>
                    </Form.Group> */}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group>
                      <Form.Label className="title-form">State</Form.Label>
                      <Select
                        placeholder="State"
                        value={selectedState}
                        onChange={(state) => { selectState(state) }}
                        className={deliveryError.hasOwnProperty('delivery_state') ? 'is-invalid' : ''}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        value={
                          {
                            label: statesOption.findIndex(x => (x.id).toString() === initDeliveryAddress.delivery_state.toString()) !== - 1 ? statesOption[statesOption.findIndex(x => (x.id).toString() === initDeliveryAddress.delivery_state.toString())].name : '',
                            value: initDeliveryAddress.delivery_state
                          }
                        }
                        styles={
                          {
                              menu: styles => ({ ...styles, zIndex: 3 }),
                              control: styles => ({ ...styles, borderColor: deliveryError.hasOwnProperty('delivery_state') ? '#dc3545' : '#ced4da' })
                          }
                        }
                        options={statesSorted(statesOption).map(state => {
                          let name = state.name.replace("(", " (")
                          return {
                            label: name,
                            value: state.id
                          }
                        })}
                      />
                      <div className="invalid-feedback">{deliveryError.delivery_state}</div>
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group>
                      <Form.Label className="title-form">ZIP Code</Form.Label>
                      <Form.Control 
                        type="text"
                        placeholder="Zip Code"
                        value={initDeliveryAddress.delivery_zipcode}
                        isInvalid={deliveryError.hasOwnProperty('delivery_zipcode')}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, delivery_zipcode: e.target.value})}
                      />
                      <div className="invalid-feedback">{deliveryError.delivery_zipcode}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group>
                      <Form.Label className="title-form">Phone Number</Form.Label>
                      <NumberFormat
                        format="(###) ###-####"
                        className="form-control"
                        value={initDeliveryAddress.delivery_phone_number}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, delivery_phone_number: e.target.value})}
                        placeholder="Phone Numer"
                      />
                      <div className="invalid-feedback">{deliveryError.delivery_street_address}</div>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              {
                user.user_type_id === 1 &&
                <Row className="mt-3">
                  <Col lg={5} md={5}>
                    <Form.Group>
                      <Form.Label className="font-weight-bold">Shipping Method</Form.Label>
                      <Select 
                        name="shipping_method"
                        placeholder="Select Shipping Method"
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        value={
                          {
                            label: initDeliveryAddress.shipping_method,
                            value: initDeliveryAddress.shipping_method
                          }
                        }
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, shipping_method: e.value})}
                        styles={
                          {
                              menu: styles => ({ ...styles, zIndex: 3 })
                          }
                        }
                        options={shippingMethods}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              }
              <Form.Group className="mt-3">
                <Form.Label className="title-form">Special Instructions</Form.Label>
                <Form.Control 
                  type="text"
                  value={initDeliveryAddress.delivery_special_instruction}
                  onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, delivery_special_instruction: e.target.value})}
                />
              </Form.Group>
              {
                user.user_type_id === 1 &&
                <Form.Group className="mt-3">
                  <Form.Label className="font-weight-bold">Requestor</Form.Label>
                  <Form.Control
                    as="input"
                    value={initDeliveryAddress.requestor}
                    onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, requestor: e.target.value})}
                    placeholder="John Doe"
                  />
                </Form.Group>
              }
            </div>
            : <div>
              <div className="mb-3">Please enter the information of the person who will pickup this order. We will call this person when the order is ready to pickup.</div>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group>
                    <Form.Label className="title-form required">Name</Form.Label>
                    <Form.Control 
                      type="text"
                      placeholder="Name"
                      value={initDeliveryAddress.pickup_name}
                      isInvalid={deliveryError.hasOwnProperty('pickup_name')}
                      onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, pickup_name: e.target.value})}
                    />
                    <div className="invalid-feedback">{deliveryError.pickup_name}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <Form.Group>
                    <Form.Label className="title-form required">Phone Number</Form.Label>
                    <NumberFormat
                        format="(###) ###-####"
                        className="form-control"
                        value={initDeliveryAddress.pickup_phone_number}
                        style={{borderColor: deliveryError.hasOwnProperty('pickup_phone_number') ? '#dc3545' : '#ced4da'}}
                        isInvalid={deliveryError.hasOwnProperty('pickup_phone_number')}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, pickup_phone_number: e.target.value})}
                        placeholder="Phone Number"
                      />
                    <div className={`${deliveryError.hasOwnProperty('pickup_phone_number') ? "d-block invalid-feedback" : ''}`}>{deliveryError.pickup_phone_number}</div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={6}>
                  <div style={{ background: "#f8f8f8", padding: "20px" }}>
                    <Form.Label className="title-form">Pickup Address</Form.Label>
                    <div>{initDeliveryAddress.pickup_address}</div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={6} md={6}>
                  <Form.Group>
                    <Form.Label className="title-form">Special Instructions</Form.Label>
                    <Form.Control 
                      type="text"
                      value={initDeliveryAddress.pickup_special_instruction}
                      onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, pickup_special_instruction: e.target.value})}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={6} md={6}>
                  {
                    user.user_type_id === 1 &&
                    <Form.Group>
                      <Form.Label className="font-weight-bold">Requestor</Form.Label>
                      <IconContext.Provider
                        value={{ size: '15px', color: "#ef5350" }}
                      >
                          <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                              <FaInfoCircle />
                          </span>
                      </IconContext.Provider>
                      <Form.Control
                        as="input"
                        value={initDeliveryAddress.requestor}
                        onChange={(e) => setInitDeliveryAddress({...initDeliveryAddress, requestor: e.target.value})}
                        placeholder="John Doe"
                      />
                    </Form.Group>
                  }
                </Col>
              </Row>
            </div>
          }
          <Form.Group className="mt-3">
            <Form.Check 
              id="receive_pin_order"
              type="switch"
              name="receive_pin_order"
              checked={initDeliveryAddress.receive_pin_order}
              value={initDeliveryAddress.receive_pin_order}
              onChange={(e) => setInitDeliveryAddress({ ...initDeliveryAddress, receive_pin_order: e.target.checked })}
              label="I'd like to receive the list of pins with my order"
            />
          </Form.Group>
        </div>
      }
      <div className="button-container">
        <button className="secondary-button" onClick={() => backStep({...initDeliveryAddress})}>BACK</button>
        <button className="primary-button" variant="outline-danger" onClick={() => nextStep()}>
        {
          loadingCreateCard ?  <Spinner animation="border" variant="danger" size="sm" /> : 'NEXT'
        }
        </button>
    </div>
    </Card.Body>
  )
}

export default SecondStep
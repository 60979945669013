import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';

const DuplicateTooltip = ({ show, duplicateCard }) => {
    const [amount, setAmount] = useState(0);

    return (
        <div className={show ? 'overlay show' : 'overlay'}>
            <div className="d-flex align-items-center">
                <FormControl
                    className="mr-2"
                    type="number"
                    value={amount}
                    min={0}
                    onChange={(e) => {setAmount(parseInt(e.target.value) < 0 ? 0 : e.target.value)}}
                />
                <button style={{ padding: '.5em 1em', margin: '0', height: 'auto' }} className={parseInt(amount) > 0 ? 'search-button' : 'disabled-button'} onClick={() => parseInt(amount) > 0 ? duplicateCard(amount) : {}}>Duplicate</button>
            </div>
        </div>
    )
}

export default DuplicateTooltip;